<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
    ref="dropdown"
  >
    <template #button-content>
      <feather-icon
        :badge="notificationData.length"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
        @click="RefreshNotifications"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="oci-notification-title mb-0 mr-auto">
          Notifications
        </h4>
        <b-badge
          pill
          class="oci-notification-count"
        >
          {{ notificationData.length }} New
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
      refs="ps"
    >
      <h5 class="ml-2 mt-1 mb-1">   
        <div v-if="notificationData.length > 0">   
        You have new project offers
        </div>
      </h5>
      <!-- Account Notification -->
      
      <b-link
        v-for="notification in notificationData"
        :key="notification.notification_id"
      >
        <b-media>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{formatDate(notification.date)}}
            </span>
          </p>
          <small class="notification-text">{{ notification.site_main_address }}</small>
        </b-media>
      </b-link>
    
      <!-- System Notification Toggler -->
      <!-- <div class="media d-flex align-items-center">
        <h6 class="font-weight-bolder mr-auto mb-0">
          System Notifications
        </h6>
        <b-form-checkbox
          :checked="true"
          switch
        />
      </div> -->

      <!-- System Notifications -->
      <!-- <b-link
        v-for="notification in systemNotifications"
        :key="notification.subtitle"
      >
        <b-media>
          <template #aside>
            <b-avatar
              size="32"
              :variant="notification.type"
            >
              <feather-icon :icon="notification.icon" />
            </b-avatar>
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.title }}
            </span>
          </p>
          <small class="notification-text">{{ notification.subtitle }}</small>
        </b-media>
      </b-link> -->
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li class="dropdown-menu-footer">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        class="oci-notification-button"
        block
        @click="showPendingOffers"
      >
        View Projects
      </b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import { mapGetters } from 'vuex'
import store from '@/store'

export default {
  name: 'OciNotifications',
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
  },
  computed:{
    ...mapGetters({ notificationData: 'app/getNotificationsList' }),
    // computedObj(){
    //  return this.limit ? this.notificationData.slice(0,this.limit) : this.notificationData
    // }
  },  
  data(){
    return {
      limit: 3 // or any number you wish to limit to
    }
  },  
  directives: {
    Ripple,
  },
  setup() {
    /* eslint-disable global-require */
    /* eslint-disable global-require */

    // const systemNotifications = [
    //   {
    //     title: 'Server down',
    //     subtitle: 'USA Server is down due to hight CPU usage',
    //     type: 'light-danger',
    //     icon: 'XIcon',
    //   },
    //   {
    //     title: 'Sales report generated',
    //     subtitle: 'Last month sales report generated',
    //     type: 'light-success',
    //     icon: 'CheckIcon',
    //   },
    //   {
    //     title: 'High memory usage',
    //     subtitle: 'BLR Server using high memory',
    //     type: 'light-warning',
    //     icon: 'AlertTriangleIcon',
    //   },
    // ]

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      // systemNotifications,
      perfectScrollbarSettings,
    }
  },
  methods: {
    showPendingOffers() {      
      this.$store.dispatch('app/NotificationSeen', this.notificationData) 
      // console.log(this.$route.name)
      this.$refs.dropdown.hide(true)
      if (this.$route.name !== 'projects') {
        this.$router.push('/projects') // Should it be, projectnotifications screen (so new screen that shows only new ones)
      }
    },
    formatDate(data) {
      const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ];      
      return String(data.day) +' '+ monthNames[data.month-1]+' '+ String(data.year)      
    },    
    RefreshNotifications(){
      // this.$refs.ps.update()      

      this.$store.dispatch('app/getNewNotificationList')       
    },
  },
}
</script>

<style>

</style>
