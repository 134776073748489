<template>
  <div class="container oci-check-in-out-body">    
    <WasteNumberView/>
    <p></p>
    <b-card class="oci-check-in-out-card">  
        <b-row class="pb-0 justify-content-center">
            <b class="driver-large-text">Waste destination</b>
        </b-row>
        <b-row class="pb-1">
          <b-col cols="12">
            <b-form-group 
              label="Location"
              label-for="waste-location"
            >        
              <b-form-select
                  id="waste-location"
                  v-model="bookingDockets.location"
                  :options="wasteLocationOptions"
                  :disabled="isInputDisabled"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <!--
        <b-row class="pb-1">
            <b-col class="oci-check-in-button-container">
            <b-button
                type="submit"
                @click=""
                class="w-100"
                :disabled="isInputDisabled"
            >
                Add destination +
            </b-button>
            </b-col>
        </b-row>
        -->
        <b-row class="pb-1">
          <b-col class="col-5 oci-projects-button-container">
            <b-button
              type="submit"
              @click="GoToPreviousPage"
              class="w-100"
            >
              <feather-icon
                icon="ChevronLeftIcon"
                size="12"
              />              
              Back
            </b-button>
          </b-col>
          <b-col class="col-7 oci-check-in-button-container">
            <b-button
              type="submit"
              @click="GoToNextPage"
              class="w-100"
            >
              Next
              <feather-icon
                icon="ChevronRightIcon"
                size="12"
              />              
            </b-button>
          </b-col>
        </b-row> 

    </b-card>

  </div>
  </template>

<script>
import { mapGetters } from 'vuex'
import { BImg, BCard, BRow, BCol, BForm, BFormGroup, BFormSelect, BInputGroupAppend, BFormInput, BButton, BInputGroup, } from 'bootstrap-vue'
import { bookingsMethods } from '@/components/ui/BookingsFunctions'
import WasteNumberView from '@/views/WasteNumberView.vue'

export default {
  components: {
    BImg,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BButton,
    WasteNumberView,
  },
  data() {
    return {
      originalWasteDestination: '',
    };
  },    
  computed: {
    ...mapGetters({ currentBooking: 'app/getCurrentBookingData' }),    
    ...mapGetters({ bookingDockets: 'app/getBookingDocketDetails' }),
    ...mapGetters({ wasteLocations: 'app/getWasteLocations' }),
    wasteLocationOptions() {
      return this.wasteLocations
        .filter(location => location.carrier === this.currentBooking.carrier)
        .map(location => ({
          text: location.address_name,
          value: location.id,
        }));
    },
    isInputDisabled() {
      const bookedDate = new Date( this.currentBooking.booked_for.year,  this.currentBooking.booked_for.month - 1,  this.currentBooking.booked_for.day);
      const today = new Date();
      const isToday =
        bookedDate.getFullYear() === today.getFullYear() &&
        bookedDate.getMonth() === today.getMonth() &&
        bookedDate.getDate() === today.getDate();
      return (
        (this.currentBooking?.status === 'completed' ||
        this.currentBooking?.status === 'rejected' ||
        this.currentBooking?.status === null) &&
        !isToday
      );
    }
  },
  mounted() {
    this.originalWasteDestination = this.bookingDockets.location;
  },
  methods: {
    GoToPreviousPage() {
      this.$router.go(-1)
    },
    isTodayJob() {
      const bookedDate = new Date(
        this.currentBooking.booked_for.year,
        this.currentBooking.booked_for.month - 1,
        this.currentBooking.booked_for.day
      );
      const today = new Date();
      const isToday =
        bookedDate.getFullYear() === today.getFullYear() &&
        bookedDate.getMonth() === today.getMonth() &&
        bookedDate.getDate() === today.getDate();
      return isToday;  
    },    
    async GoToNextPage() {
      const isToday = this.isTodayJob()
      if (this.currentBooking.status === 'to_do' || this.currentBooking.status === 'started' || isToday) {
        const currentValue = this.bookingDockets.location;
        const originalValue = this.originalWasteDestination;
        if (currentValue !== originalValue) {
          let result = await bookingsMethods.saveWasteDestination(this.bookingDockets.id, this.bookingDockets.location)
          if (!result) {
            return
          }
        }
      }
      this.$router.push('/waste_docket')
    },    
  },  
}
</script>
