<template>
  <div class="oci-container-pj">
    <div class="oci-header" style="margin-bottom: 2px;">
      <b-row style="margin-bottom: 5px;">
        <b-col class="col-4">
        <div class="float-left">
          <feather-icon
            id="idgoback"
            icon="ChevronLeftIcon"
            size="24"
            @click="GoToBack"
          />
        </div>
        </b-col>
        <b-col class="col-4">
        <div class="float-center">
            Bookings
        </div>
        </b-col>             
        <b-col class="col-3">   
        <div class="float-right">
          <feather-icon
            id="idrefresh"
            :icon=this.icon
            size="24"
            :color=this.color
            @click="LoadData"
          />
        </div> 
        </b-col>
      </b-row>    
      <b-row>
        <b-col class="col-2 driver-show-all-toggle">
            <b-row>
            <b-col>
            <b-form-checkbox
              v-model="showtoday"
              switch
              @change="changeQuery()"
            >
              {{buttonmsg}}
            </b-form-checkbox>   
            </b-col>         
            </b-row>            
        </b-col>      
      </b-row>
    </div>
    <!--<hr class="w-100">-->
    <!--v-scroll="handleScroll"--> 

    <div class="wrapper">
        <div v-for="(booking, index) in bookingsList" :key="index">
        <b-table
            :fields="fields"
            :items="[booking]"
            @row-clicked="bookingSelected(booking.id, booking.status)"
            responsive
            small
            borderless
            :id="'bookingstable-' + index"
            style="box-shadow: 0px 0px 0px 1px rgba(0,0,0,0.05); border-radius: 8px; margin-top: 6px; margin-bottom: 6px;"
        >            
            <template #cell(site_main_address)="data">                
                <div :style="checkIfToday(data.item.booked_for, data.item.status)" class="spaced">
                  &nbsp;{{ formatDate(data.item.booked_for) }}&nbsp;&nbsp;
                  <label v-if="isToday(data.item.booked_for)" :style="getIsTodayColor()">&nbsp;&nbsp;TODAY!&nbsp;&nbsp;</label>
                </div>
                <div style="align: left" class="spaced">
                  &nbsp;<feather-icon v-if="data.item.type == 'Waste'" id="type" icon="TrashIcon" size="14" style="stroke-width: 3; position: relative; top: -2px;"/>
                  <feather-icon v-if="data.item.type == 'Delivery / Collection'" id="type" :icon="getTruckIcon()" size="14" style="stroke-width: 3; position: relative; top: -1px;"/>&nbsp;
                    {{ data.item.type }}
                </div>
                <div style="align: left" class="spaced">
                  &nbsp;<feather-icon id="type" icon="ClockIcon" size="14" style="stroke-width: 3; position: relative; top: -2px;"/>
                    {{ formatTime(data.item.booked_for) }}
                </div>
                <div :style="SetAddressStyle(data.item.booked_for, data.item.status)">
                    &nbsp;{{ data.item.site_main_address.split('\n')[0] }}
                </div>
            </template>
            <template #cell(site_postal_code)="data">                
                <div class="text-right spaced">                    
                    <label :style="getStatusColor(data.item.status)">{{getBookingStatus(data.item.status)}}</label>
                </div>
                <div class="spaced">
                  &nbsp;
                </div>
                <div class="spaced">
                  &nbsp;
                </div>
                <div :style="SetPostcodeStyle(data.item.booked_for, data.item.status)" class="text-right">
                    {{ data.item.site_postal_code }}
                </div>                
            </template>
            
        </b-table>
        </div>
    </div>    

  </div>
</template>
<script>
import {
  BTable, BRow, BCol, BButton, BFormGroup, BFormCheckbox, BFormRadioGroup, BFormRadio,
} from 'bootstrap-vue'
import { bookingsMethods } from '@/components/ui/BookingsFunctions'
import { mapGetters } from 'vuex'
import store from '@/store'
import router from '@/router'

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormCheckbox,
    BFormRadioGroup,
    BFormRadio,
  },
  computed: {
    ...mapGetters({ bookingsList: 'app/getDriversBookingsList' }),
  },    
  data() {
    return {
      icon: "RefreshCwIcon",
      color: "#6E6B7B",
      scrollPosition: 0,
      message: '',
      showMessage: false,
      showLogin: false,
      showtoday: true,
      buttonmsg: "Today",
      fields: [          
        {
          key: 'site_main_address', label: '', thClass: 'bg-white', tdClass: 'bg-white', 
        },
        {
          key: 'site_postal_code', label: '', thClass: 'bg-white', tdClass: 'bg-white', 
        },          
      ],
    }
  },
  created() {    
    store.commit('app/setGoToTask', true)
    this.LoadData()  
  },  
  methods: {
    LoadData() {
      let dBefore = 2
      let dAfter = 2
      if (this.showtoday) {
        dBefore = 0
        dAfter = 0
      }
      this.color = "#49A7EA"
      const dateFrom = new Date()
      dateFrom.setDate(dateFrom.getDate()-dBefore)
      const dateTo = new Date()
      dateTo.setDate(dateTo.getDate()+dAfter)
      this.$store.dispatch('app/getBookingsList', {dateFrom, dateTo})           
      setTimeout(() => this.color = "#6E6B7B", 250);
    },    
    SetScrollOnTodayJob() {
      var element = document.getElementById("special");
      if(element) { 
        element.scrollIntoView();  
      }
    },     
    formatDate(data) {
      if (!data || !data.day || !data.month || !data.year) {
        return "-";
      }      
      const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ];      
      return String(data.day) +' '+ monthNames[data.month-1]+' '+ String(data.year)      
    },
    formatTime(data) {
      if (!data || data.hour == null || data.minute == null) {
        return "-";
      }

      let hour = data.hour;
      const minute = data.minute.toString().padStart(2, "0");
      const period = hour >= 12 ? "pm" : "am";

      // Convert hour to 12-hour format
      hour = hour % 12 || 12;

      return `${hour}:${minute} ${period}`;
    },    
    GoToBack() {
      this.$router.go(-1)
    },  
    getBookingStatus(state) {    
      // console.log(state)  
      if(state === 'to_do')
        return '\u00A0\u00A0TO DO\u00A0\u00A0'
      else if(state === 'started')
        return '\u00A0\u00A0STARTED\u00A0\u00A0'
      else if(state === 'completed')
        return '\u00A0\u00A0COMPLETED\u00A0\u00A0'
      else if(state === 'rejected')
        return '\u00A0\u00A0Rejected\u00A0\u00A0'
      else if(state === 'cancelled')
        return '\u00A0\u00A0CANCELLED\u00A0\u00A0'
      else
        return '\u00A0\u00A0COMPLETED\u00A0\u00A0'
    },   
    tdClassFunc(row) {
      //if (row > 50) {
      //  return 'bg-primary';
      //}
      //return 'bg-primary';
    },
    handleScroll({ target: { scrollTop, clientHeight, scrollHeight }}){     
        //
    },
    scrollUp(){
        //          
    },
    scrollDown(){
        //
    },    
    getStatusColor(state){
      if(state === 'to_do') 
        return 'color: #FF9F43; background-color: rgba(255, 159, 67, 0.2); border-radius: 25px; font-weight: bold;'   
      if(state === 'started') 
        return 'color: #49A7EA; background-color: rgba(73, 167, 234, 0.2); border-radius: 25px; font-weight: bold;'
      if(state === 'completed')
        return 'color: #6E6B7B; background-color: rgba(110, 107, 123, 0.2); border-radius: 25px; font-weight: bold;' 
      if(state === 'rejected')
        return 'color: #EA5455; background-color: rgba(234, 84, 85, 0.2); border-radius: 25px; font-weight: bold;'      
      if(state === 'cancelled')
        return 'color: #EA5455; background-color: rgba(234, 84, 85, 0.2); border-radius: 25px; font-weight: bold;'      
      return 'color: #6E6B7B; background-color: rgba(110, 107, 123, 0.2); border-radius: 25px; font-weight: bold;'                      
    },
    getIsTodayColor(){     
      return 'color: #002437; background-color: #F6FF00; border-radius: 25px; font-weight: bold;'            
    },
    getTruckIcon() {
      return 'TruckIcon'
    },
    SetAddressStyle(data, state){
      if(state === 'completed') {
        return 'align-text: left; color: #cecdd6'
      } else {
        if (!data) {
          return 'align-text: left;' 
        }         
        const today = new Date()    
        if (today.getDate() === data.day && today.getMonth()+1 === data.month && today.getFullYear() === data.year)      
          return 'align-text: left; font-weight: bold;'
        else
          return 'align-text: left;'  
      }    
    },
    SetPostcodeStyle(data, state){
      if(state === 'completed'){
        return 'align-text: right; color: #cecdd6'
      } else {
        if (!data) {
          return 'align-text: right;' 
        }         
        const today = new Date()  
        if (today.getDate() === data.day && today.getMonth()+1 === data.month && today.getFullYear() === data.year)      
          return 'align-text: right; font-weight: bold;'
        else
          return 'align-text: right;'
      }
    },    
    checkIfToday(data){
      if (!data) {
        return 'align: left'
      }        
      const today = new Date()    
      if (today.getDate() === data.day && today.getMonth()+1 === data.month && today.getFullYear() === data.year)
        return 'align: left; color: #86Ac40; font-weight: bold;' // border-bottom-style: solid; border-bottom-color: green;
      else
        return 'align: left'
    },
    checkIfToday(data, state){
      const today = new Date()    
      if (today.getDate() === data.day && today.getMonth()+1 === data.month && today.getFullYear() === data.year)
        if(state === 'completed')
          return 'align: left; color: rgb(145, 185, 145);  font-weight: bold;'
        else     
          return 'align: left; color: green;  font-weight: bold;'
      else
        if(state === 'completed')
          return 'align: left: color: #cecdd6'
        else
          return 'align: left'
    },    
    isToday(data) {
      if (!data) {
        return false;
      }
      const today = new Date()
      if (today.getDate() === data.day && today.getMonth()+1 === data.month && today.getFullYear() === data.year)
        return true;
      else
        return false;
    },
    bookingSelected(bookingId, status) {
        console.log('bookingSelected', bookingId, status)  
        store.commit('app/changeDriverBookingID', bookingId)
        this.$router.push('/driverprocess');
    },
    getId(id){
        return id
    },
    changeQuery(){
      // console.log('this.showtoday', this.showtoday); // The value is already updated by v-model      
      this.LoadData();
    },
  },
}
</script>

<style scoped>
.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  width: 100%;
}

.sub-header-item-1 {
  grid-column: 1;
  backdrop-filter: #fff;
  width: 50%;
}

.sub-header-item-2 {
  grid-column: 2;
  background: #fff;
  width: 50%;
}

.wrapper {
  overflow: auto;
  height: 70vh;
  scrollbar-width: none;
}

 </style>
