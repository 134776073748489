<template>
  <div class="oci-login-body">
    <div class="oci-login-position">
      <div class="oci-login-box">
        <b-row>
          <div class="oci-login-logo">
            <b-img
              :src="appLogoImage"
              alt="logo"
            />
          </div>
        </b-row>
        <b-row>
          <!-- Login-->
          <b-col
            class="d-flex align-items-center auth-bg px-2 p-lg-5"
          >
            <b-col
              sm="8"
              md="6"
              lg="12"
              class="px-xl-2 mx-auto"
            >

              <b-card-title
                title-tag="h2"
                class="font-weight-bold mb-1 text-left"
              >
                Please login
              </b-card-title>
              <!-- form -->
              <validation-observer ref="loginValidation">
                <b-form
                  class="auth-login-form mt-2"
                  @submit.prevent
                >
                  <!-- email -->
                  <b-form-group
                    label="Email"
                    label-for="login-email"
                    class="text-left"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Email"
                      rules="required|email"
                    >
                      <b-form-input
                        id="login-email"
                        v-model="userEmail"
                        :state="errors.length > 0 ? false:null"
                        name="login-email"
                        placeholder="john@example.com"
                        @keydown.native="enableLogin"
                      />
                      <small class="text-danger text-left">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- forgot password -->
                  <b-form-group class="text-left">
                    <div class="d-flex justify-content-between">
                      <label for="login-password">Password</label>
                      <b-link @click="resetPassword">
                        <small>Reset Password</small>
                      </b-link>
                    </div>
                    <validation-provider
                      #default="{ errors }"
                      name="Password"
                      rules="required"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid':null"
                      >
                        <b-form-input
                          id="login-password"
                          v-model="password"
                          :state="errors.length > 0 ? false:null"
                          class="form-control-merge"
                          :type="passwordFieldType"
                          name="login-password"
                          placeholder="············"
                          @keydown.native="enableLogin"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            class="cursor-pointer"
                            :icon="passwordToggleIcon"
                            @click="togglePasswordVisibility"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger text-left">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- checkbox -->
                  <b-form-group>
                    <b-form-checkbox
                      id="remember-me"
                      v-model="status"
                      name="checkbox-1"
                      class="text-left mb-1"
                    >
                      Remember Me
                    </b-form-checkbox>
                  </b-form-group>

                  <!-- submit buttons -->
                  <b-button
                    type="submit"
                    class="oci-login-button"
                    block
                    :disabled="loginDisabled"
                    @click="validationForm"
                  >
                    Login
                  </b-button>
                </b-form>
              </validation-observer>
              <!-- social buttons -->
              <div class="auth-footer-btn d-flex justify-content-center" />
            </b-col>
          </b-col>
        <!-- /Login-->
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { $themeConfig } from '@themeConfig'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { loginMethods } from '@/auth/rpc/ociLoginFunctions'
import swal from 'sweetalert2'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      loginDisabled: false,
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    appLogoImage() {
      const { appLogoImage } = $themeConfig.app
      return appLogoImage
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    showMessage(message, inError) {
      swal.fire({
        title: inError ? 'Error' : 'Success!',
        text: message,
        icon: inError ? 'error' : 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    showResetMessage(message, inError) {
      swal.fire({
        title: inError ? 'Error' : 'Thank you',
        text: message,
        icon: inError ? 'error' : 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },    
    showWarningMessage(msgDescription, msgTitle) {
      swal.fire({
        title: msgTitle,
        text: msgDescription,
        icon: 'warning',
        confirmButtonText: 'Close X',
        customClass: {
          confirmButton: 'btn oci-close-button',
        },        
        buttonsStyling: false,
      })
    },
    resetPasswordCompleted() {
      this.showResetMessage('If the email you entered is in our system we will send you a link to reset your password.', false)
    },
    async resetPassword() {
      if (this.userEmail === '') {
        this.showWarningMessage('Please enter your email address to reset your password.', 'Email needed')
        return
      }
      await loginMethods.ResetPassword(this.userEmail, this.resetPasswordCompleted)
        .then(result => {
          // console.log(result)
          this.loginDisabled = false
        })
        .catch(error => {
          // console.log(error)
          this.loginDisabled = false
        })
    },    
    enableLogin() {
      console.log('enableLogin')
      this.userEmail = this.userEmail.trim()
      this.loginDisabled = false
    },
    async doLogin() {
      store.commit('app/setFromAllTasks', false)
      this.loginDisabled = true
      await loginMethods.Login(this.userEmail.trim(), this.password, this.enableLogin)
        .then(result => {
          console.log('doLogin')
          console.log(result)
          console.log('after result')
          this.loginDisabled = false
        })
        .catch(error => {
          console.log(error)
          this.loginDisabled = false
        })
    },

    validationForm() {
      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          this.doLogin()
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/style.scss';
</style>
