<template>
  <div class="container oci-check-in-out-body">    
    <WasteNumberView/>
    <p></p>
    <b-card class="oci-check-in-out-card">  
        <b-row class="pb-0 justify-content-center">
            <b class="driver-large-text">Client Signature</b>
        </b-row>
        <b-row class="pb-1 align-items-center">
            <b-col> 
            <b-form-checkbox
                id="client-not-available"
                v-model="bookingDockets.client_not_available"
                name="checkbox-1"
                class="align-items-center"
                :disabled="isInputDisabled"
            >
                Client not available on site
            </b-form-checkbox>
            </b-col>
        </b-row>        
        <b-row>
            <b-col>
            <b-form-group
                label=""
                label-for="waste-select"
            >
                <div v-for="(item, index) in bookingDockets.items.filter(item => item.isVisible)" :key="index">
                <b-form-group
                label="Material"
                label-for="waste-material"
                >
                <b-form-select
                    :id="'waste-select-' + index"
                    v-model="item.material"
                    :options="wasteMaterialOptions"
                    disabled
                />
                </b-form-group>
                <b-row class="align-items-center">
                  <b-col cols="3">
                    <b-form-group
                    label="Bins"
                    label-for="waste-bins"
                    >
                      <b-form-input
                      v-model="item.bins"
                      placeholder=""
                      name="name-edit"
                      disabled
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="4">
                    <b-form-group
                    label="Capacity-Lt"
                    label-for="waste-capacity"
                    >                  
                      <b-form-select
                      :id="'waste-bin-select-' + index"
                      v-model="item.bin_type"
                      :options="wasteBinOptions"
                      placeholder="Choose a waste bin"
                      disabled
                      ></b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col class="col-2 oci-projects-button-container">
                    &nbsp;
                  </b-col>
                  <b-col class="col-2 oci-check-in-button-container">
                    &nbsp;
                  </b-col>
                </b-row>
                </div>                
            </b-form-group>
            </b-col>
        </b-row> 
        <b-row class="pb-0 align-items-center">
            <b-col>
            <b-form-group
            label="Client name"
            label-for="waste-client-name"
            >            
              <b-form-input
              v-model="bookingDockets.client_print_name"
              placeholder=""
              name="name-edit"
              :disabled="isInputDisabledSignature"
              />
            </b-form-group>
            </b-col>
        </b-row> 

        <b-row>
            <b-col>
              <b-form-group
              label="Client signature"
              label-for="waste-sign"
              >
              <div class="signature-container" :disabled="isInputDisabledSignature">
                  <canvas ref="signatureCanvas" class="signature-canvas" :class="{ disabled: isInputDisabledSignature }"></canvas>
              </div>
            </b-form-group>
            </b-col>
        </b-row>

        <b-row class="pb-1">
          <b-col class="col-5 oci-projects-button-container">
            <b-button
              type="submit"
              @click="GoToPreviousPage"
              class="w-100"
            >
              <feather-icon
                icon="ChevronLeftIcon"
                size="12"
              />              
              Back
            </b-button>
          </b-col>
          <b-col class="col-7 oci-check-in-button-container">
            <b-button
              type="submit"
              @click="GoToNextPage"
              class="w-100"
            >
              Next
              <feather-icon
                icon="ChevronRightIcon"
                size="12"
              />              
            </b-button>
          </b-col>
        </b-row> 

    </b-card>

  </div>
  </template>

<script>
import { mapGetters } from 'vuex'
import { BImg, BCard, BRow, BCol, BForm, BFormGroup, BFormSelect, BInputGroupAppend, BFormInput, BFormCheckbox, BButton, BInputGroup, } from 'bootstrap-vue'
import WasteNumberView from '@/views/WasteNumberView.vue'
import { bookingsMethods } from '@/components/ui/BookingsFunctions'
import SignaturePad from 'signature_pad';

export default {
  components: {
    BImg,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BFormCheckbox,
    BButton,
    WasteNumberView,
  },
  props: {
    item: {
      type: Object,
      default: () => ({
        number_of_240_bins: 0,
        number_of_360_bins: 0,
        number_of_660_bins: 0,
      }),
    },
  },   
  data() {
    return {
      status: '',
      originalClientPrintName: '',
      originalClientSignature: null,
      wasteBinOptions: [
        { text: '240', value: '240' },
        { text: '360', value: '360' },
        { text: '660', value: '660' },
      ],
      signaturePad: null, 
    }
  },  
  mounted() {
    //console.log('this.bookingDockets.client_signature', this.bookingDockets.client_signature)
    this.originalClientPrintName = this.bookingDockets.client_print_name;
    const canvas = this.$refs.signatureCanvas;
    this.signaturePad = new SignaturePad(canvas, {
      backgroundColor: 'white', // Set the canvas background to white
      penColor: 'black',        // Set the pen color
      minWidth: 0.5,            // Minimum pen thickness (slim)
      maxWidth: 1.5,            // Maximum pen thickness      
    });

    // Resize canvas for better quality
    this.resizeCanvas();
    if (this.bookingDockets.client_signature?.base64) {
      this.originalClientSignature = this.bookingDockets.client_signature.base64;
      this.loadSignature(this.originalClientSignature);
      this.$forceUpdate();
    }
  },  
  computed: {
    ...mapGetters({ currentBooking: 'app/getCurrentBookingData' }),
    ...mapGetters({ bookingDockets: 'app/getBookingDocketDetails' }),
    ...mapGetters({ wasteMaterials: 'app/getWasteMaterials' }),
    wasteMaterialOptions() {
      return this.wasteMaterials.map(material => ({
        text: material.name,
        value: material.id,
      }));
    },
    isInputDisabled() {
      const bookedDate = new Date( this.currentBooking.booked_for.year,  this.currentBooking.booked_for.month - 1,  this.currentBooking.booked_for.day);
      const today = new Date();
      const isToday =
        bookedDate.getFullYear() === today.getFullYear() &&
        bookedDate.getMonth() === today.getMonth() &&
        bookedDate.getDate() === today.getDate();
      return (
        (this.currentBooking?.status === 'completed' ||
        this.currentBooking?.status === 'rejected' ||
        this.currentBooking?.status === null) &&
        !isToday
      );
    },
    isInputDisabledSignature() {
      const bookedDate = new Date( this.currentBooking.booked_for.year,  this.currentBooking.booked_for.month - 1,  this.currentBooking.booked_for.day);
      const today = new Date();
      const isToday =
        bookedDate.getFullYear() === today.getFullYear() &&
        bookedDate.getMonth() === today.getMonth() &&
        bookedDate.getDate() === today.getDate();
      return (
        ((this.currentBooking?.status === 'completed' ||
        this.currentBooking?.status === 'rejected' ||
        this.currentBooking?.status === null) &&
        !isToday) ||
        this.bookingDockets?.is_signed
      );
    }    
  }, 
  methods: {
    GoToPreviousPage() {
      this.$router.go(-1)
    },
    isTodayJob() {
      const bookedDate = new Date(
        this.currentBooking.booked_for.year,
        this.currentBooking.booked_for.month - 1,
        this.currentBooking.booked_for.day
      );
      const today = new Date();
      const isToday =
        bookedDate.getFullYear() === today.getFullYear() &&
        bookedDate.getMonth() === today.getMonth() &&
        bookedDate.getDate() === today.getDate();
      return isToday;  
    },    
    async GoToNextPage() {
      const isToday = this.isTodayJob()
      if (this.currentBooking.status === 'to_do' || this.currentBooking.status === 'started' || isToday) {
        const currentValue = this.bookingDockets.client_print_name;
        const originalValue = this.originalClientPrintName;
        //if (currentValue !== originalValue) {
          let client_signature = this.saveSignature();
          const currentSignatureBase64 = client_signature.replace('data:image/png;base64,', '');
          const hasSignatureChanged = currentSignatureBase64 !== this.originalClientSignature;
          //console.log('this.bookingDockets.client_signatureA', this.bookingDockets.client_signature)
          if (hasSignatureChanged || currentValue !== originalValue) {
            this.bookingDockets.client_signature = {
              __class__: "bytes",
              base64: client_signature.replace('data:image/png;base64,', ''),
            };
            //console.log('this.bookingDockets.client_signatureB', this.bookingDockets.client_signature)
            if (!this.bookingDockets?.is_signed) {
              let result = await bookingsMethods.saveClientSignatureDetails(this.bookingDockets.id, this.bookingDockets.client_print_name, this.bookingDockets.client_not_available, client_signature)
              if (!result) {
                return
              }
              this.bookingDockets.is_signed = true
            }
          }
        //}
      }
      this.$router.push('/waste_dest')
    },
    loadSignature(base64Signature) {
      const canvas = this.$refs.signatureCanvas;
      const ctx = canvas.getContext('2d');
      const image = new Image();
      image.onload = () => {
        ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear the canvas
        ctx.drawImage(image, 0, 0, canvas.width, canvas.height); // Draw the signature
      };
      image.src = `data:image/png;base64,${base64Signature}`;
    },
    updateSelectedWasteBin(item) {
      if (item.number_of_240_bins > 0) {
        this.selectedWasteBin = '240';
      } else if (item.number_of_360_bins > 0) {
        this.selectedWasteBin = '360';
      } else if (item.number_of_660_bins > 0) {
        this.selectedWasteBin = '660';
      } else {
        this.selectedWasteBin = null; // No value selected
      }
    },
    resizeCanvas() {
      const canvas = this.$refs.signatureCanvas;
      const ratio = Math.max(window.devicePixelRatio || 1, 1);
      canvas.width = canvas.offsetWidth * ratio;
      canvas.height = canvas.offsetHeight * ratio;
      canvas.getContext("2d").scale(ratio, ratio);

      // Clear the canvas to avoid distorted content
      this.signaturePad.clear();
    },
    clearSignature() {
      this.signaturePad.clear();
    },
    saveSignature() {
      /*if (this.signaturePad.isEmpty()) {
        alert("Please provide a signature first.");
        return;
      }
      */
      const signatureImage = this.signaturePad.toDataURL(); // Save as a base64 image
      //console.log("Signature Image URL:", signatureImage);
      return signatureImage;
      // You can send `signatureImage` to your server or save it elsewhere
    },      
  },  
}
</script>
