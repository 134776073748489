// import Vue from 'vue'
import axios from 'axios'
import rpc from '@/auth/rpc/ociRpc'
import Configuration from '@/utils/configuration'
import { generalMethods } from '@/components/General-Functions'
import store from '@/store'
import router from '@/router'
// import { stringify } from 'uuid'

/* eslint-disable */
export const bookingsMethods = {
  path: process.env.NODE_ENV === 'production' ? Configuration.value('backendHost') : 'http://localhost:8000/lawmens/',
  loginUser: '',
  loginPw: '',
  loginID: '',
  loginToken: '',
  webToken: '',
  userDetails: '',
  driverBookingID: -1,
  sessionAuth: '',
  event_time_pc: null,
  callback: null,

  reset() {
    this.siteOperativeId = '';
    this.loginUser = '';
    this.loginPw = '';
    this.loginID = '';
    this.loginToken = '';
    this.webToken = '';
    this.userDetails = '';
    this.driverBookingID = -3;
    this.sessionAuth = ''; 
    this.event_time_pc = null;
  },

async checkBookingDetails(bookingId, bookingStatus, reason, reason_notes, type, booked_for) {
    console.log('checkBookingDetails:', bookingId, bookingStatus, type, booked_for)

    const wasteMaterials = store.getters['app/getWasteMaterials'];
    if (wasteMaterials.length === 0 && type === 'Waste') {
      this.getWasteMaterials()
    }
    const wasteLocations = store.getters['app/getWasteLocations'];
    console.log('wasteLocations', wasteLocations)
    if (wasteLocations.length === 0 && type === 'Waste') {
      this.getWasteLocations()
    }
    const dcLineTypes = store.getters['app/getDCLineTypes'];
    console.log('dcLineTypes', dcLineTypes)
    if (dcLineTypes.length === 0 && type === 'Delivery / Collection') {
      console.log('before getDCLineTypes')
      this.getDCLineTypes()
    }    

    if (bookingStatus === 'to_do') {
      const bookedForDate = new Date(booked_for.year, booked_for.month - 1, booked_for.day);
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      //console.log('bookedForDate', bookedForDate)
      //console.log('today', today)
      if (today < bookedForDate) {
        generalMethods.messageBox('', 'Too early to start this job', 'warning');
        return;
      }      
      if (type === 'Waste') {
        this.createBookingDetails(bookingId)
      }else if (type === 'Delivery / Collection') {
        // this.createBookingLines(bookingId)
        await this.saveBooking(bookingId);
        await this.getDCBookingLines(bookingId);        
      }
    } else if (bookingStatus === 'started' || bookingStatus === 'completed' || bookingStatus === null) {
      if (type === 'Waste') {
        this.getBookingDetails(bookingId)
      }else if (type === 'Delivery / Collection') {
        this.getDCBookingLines(bookingId)
      }      
    } else if (bookingStatus === 'rejected') {
      generalMethods.messageBox('', 'This job has been rejected', 'error');
    } else if (bookingStatus === 'cancelled') {
      let notes = ''
      if (reason === 'no_on_site') {
        notes = "No one present on site";
      } else if (reason === 'job_resheduled') {
        notes = "Job resheduled";
      } else if (reason === 'other') {
        notes = "Other "+reason_notes;
      }   
      generalMethods.messageBox('', 'You have cancelled this job: '+notes, 'warning');
    }
  },

async createBookingDetails(bookingId) {
    console.log('createBookingDetails', bookingId)

    const path = process.env.NODE_ENV === 'production' ? Configuration.value('backendHost') : 'http://localhost:8000/lawmens/'
    const sAuth1 = String('admin:') + String(rpc.getUserId()) + String(':') + String(rpc.getToken())
    const sAuth = String(btoa(sAuth1))
    const sessionAuth = String('Session ') + sAuth
    const config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: sessionAuth,
      },
    }

    const payload = {
      id: '1',
      method: 'model.prosite.waste.collection.docket.create',
      params: [[{ collection: bookingId }], {}],
    }

    try {
      const response = await axios.post(path, payload, config);
      console.log('response.data', response.data);
      if (response.data.error !== undefined) {
        return;
      }
      await this.saveBooking(bookingId);
      await this.getBookingDetails(bookingId);
    } catch (error) {
      console.log('createBookingDetails catch:', error);
    }
  },  

async saveBooking(bookingId) {
    console.log('saveBooking', bookingId)

    const path = process.env.NODE_ENV === 'production' ? Configuration.value('backendHost') : 'http://localhost:8000/lawmens/'
    const sAuth1 = String('admin:') + String(rpc.getUserId()) + String(':') + String(rpc.getToken())
    const sAuth = String(btoa(sAuth1))
    const sessionAuth = String('Session ') + sAuth
    const config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: sessionAuth,
      },
    }

    const payload = {
      id: '1',
      method: 'model.prosite.waste.collection.write',
      params: [[bookingId], { status: 'started' }, {}], //, items_to_collect: true
    }

    axios.post(path, payload, config)
      .then(response => {
        console.log('response.data', response.data)
        //store.commit('app/setCurrentBookingItemsToCollect', true);
        if (response.data.error !== undefined) {
          return
        }
      })
      .catch(error => {
        console.log('saveBooking catch:', error)
      }) 
  },  

  async saveWasteDocketStatus(bookingId, status) {
    console.log('saveWasteDocketStatus', bookingId)

    const path = process.env.NODE_ENV === 'production' ? Configuration.value('backendHost') : 'http://localhost:8000/lawmens/'
    const sAuth1 = String('admin:') + String(rpc.getUserId()) + String(':') + String(rpc.getToken())
    const sAuth = String(btoa(sAuth1))
    const sessionAuth = String('Session ') + sAuth
    const config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: sessionAuth,
      },
    }

    const payload = {
      id: '1',
      method: 'model.prosite.waste.collection.write',
      params: [[bookingId], { status: status }, {}],
    }

    axios.post(path, payload, config)
      .then(response => {
        console.log('response.data', response.data)
        if (response.data.error !== undefined) {
          return
        }
      })
      .catch(error => {
        console.log('saveWasteDocketStatus catch:', error)
      }) 
  },  

async cancelBookings(bookingId, reason, reason_notes) {
    console.log('cancelBookings', bookingId)

    const path = process.env.NODE_ENV === 'production' ? Configuration.value('backendHost') : 'http://localhost:8000/lawmens/'
    const sAuth1 = String('admin:') + String(rpc.getUserId()) + String(':') + String(rpc.getToken())
    const sAuth = String(btoa(sAuth1))
    const sessionAuth = String('Session ') + sAuth
    const config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: sessionAuth,
      },
    }

    const payload = {
      id: '1',
      method: 'model.prosite.waste.collection.write',
      params: [[bookingId], { reason: reason, reason_notes: reason_notes, status: 'cancelled' }, {}],
    }

    axios.post(path, payload, config)
      .then(response => {
        console.log('response.data', response.data)
        if (response.data.error !== undefined) {
          return
        }
      })
      .catch(error => {
        console.log('cancelBookings catch:', error)
      }) 
},  

async getBookingDetails(bookingId, go_to_veh_reg = true) {
    console.log('getBookingDetails', bookingId)
    const path = process.env.NODE_ENV === 'production' ? Configuration.value('backendHost') : 'http://localhost:8000/lawmens/'
    const sAuth1 = String('admin:') + String(rpc.getUserId()) + String(':') + String(rpc.getToken())
    const sAuth = String(btoa(sAuth1))
    const sessionAuth = String('Session ') + sAuth
    const config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: sessionAuth,
      },
    }

    const filter = ['collection', '=', bookingId]
    const offset = 0
    const limit = 1

    const payload = {
      id: '1',
      method: 'model.prosite.waste.collection.docket.search_read',
      params: [[filter], offset, limit, null, ['collection', 'job', 'notes', 'location', 'carrier_company', 'actual_weight', 'items.number_of_240_bins', 'items.number_of_360_bins', 'items.number_of_660_bins', 'items.number_of_bins', 'items.estimated_weight', 'items.material', 'docket_number', 'additional_information', 'driver_print_name', 'client_print_name', 'waste_docket_number', 'vehicle_registration_number', 'client_not_available', 'client_signature', 'supporting_documents.name', 'supporting_documents.data'], { web_user_session: rpc.getWebToken() }],
    }

    axios.post(path, payload, config)
      .then(response => {
        store.commit('app/clearBookingDocketDetails', []);

        if (response.data.error !== undefined) {
          return
        }

        if (response.data.result.length > 0) {
          console.log(response.data.result)
          store.commit('app/setBookingDocketDetails', response.data.result);
          if (go_to_veh_reg) {
            router.push('/vehicle_reg');
          }
          return
        }

      })
      .catch(error => {
        console.log('getBookingDetails catch:', error)
      })    
  },  

  async saveVehicleRegistrationDetails(docketId, vehicle_registration_number) {
    console.log('saveVehicleRegistrationDetails', docketId)

    const path = process.env.NODE_ENV === 'production' ? Configuration.value('backendHost') : 'http://localhost:8000/lawmens/'
    const sAuth1 = String('admin:') + String(rpc.getUserId()) + String(':') + String(rpc.getToken())
    const sAuth = String(btoa(sAuth1))
    const sessionAuth = String('Session ') + sAuth
    const config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: sessionAuth,
      },
    }

    const payload = {
      id: '1',
      method: 'model.prosite.waste.collection.docket.write',
      params: [[docketId], { vehicle_registration_number: vehicle_registration_number }, {}],
    }

    try {
      const response = await axios.post(path, payload, config);
      console.log('response.data', response.data);
      if (response.data.error !== undefined) {
        return true;
      }
      return true;
    } catch (error) {
      console.log('saveVehicleRegistrationDetails catch:', error);
      generalMethods.messageBox('', 'Fail to save', 'error');
      return false; // Ensure a value is returned even in case of failure
    }
  },  
  
  async saveClientSignatureDetails(docketId, client_print_name, client_not_available, client_signature) {
    console.log('saveClientSignatureDetails', docketId)

    const path = process.env.NODE_ENV === 'production' ? Configuration.value('backendHost') : 'http://localhost:8000/lawmens/'
    const sAuth1 = String('admin:') + String(rpc.getUserId()) + String(':') + String(rpc.getToken())
    const sAuth = String(btoa(sAuth1))
    const sessionAuth = String('Session ') + sAuth
    const config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: sessionAuth,
      },
    }
    if (client_signature) {
      let signature = {"__class__": "bytes", "base64": client_signature.replace('data:image/png;base64,', '')}
      const payload = {
        id: '1',
        method: 'model.prosite.waste.collection.docket.write',
        params: [[docketId], { client_print_name: client_print_name, client_not_available: client_not_available, client_signature: signature }, {}],
      }
      try {
        const response = await axios.post(path, payload, config);
        console.log('response.data', response.data);
        if (response.data.error !== undefined) {
          // Handle the error scenario if needed
          return true;
        }
        return true;
      } catch (error) {
        console.log('saveClientSignatureDetails catch:', error);
        generalMethods.messageBox('', 'Fail to save', 'error');
        return false
      } 
    } else {
      const payload = {
        id: '1',
        method: 'model.prosite.waste.collection.docket.write',
        params: [[docketId], { client_print_name: client_print_name, client_not_available: client_not_available }, {}],
      }
      try {
        const response = await axios.post(path, payload, config);
        console.log('response.data', response.data);
        if (response.data.error !== undefined) {
          // Handle the error scenario if needed
          return true;
        }
        return true;
      } catch (error) {
        console.log('saveClientSignatureDetails catch:', error);
        generalMethods.messageBox('', 'Fail to save', 'error');
        return false        
      }     
    }
  },  

  async saveWasteCollectedDetails(bookingId, docketId, items) {
    console.log('saveWasteCollectedDetails', items)

    const path = process.env.NODE_ENV === 'production' ? Configuration.value('backendHost') : 'http://localhost:8000/lawmens/'
    const sAuth1 = String('admin:') + String(rpc.getUserId()) + String(':') + String(rpc.getToken())
    const sAuth = String(btoa(sAuth1))
    const sessionAuth = String('Session ') + sAuth
    const config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: sessionAuth,
      },
    }

    let data_create = [];
    let data_write = [];
    let data_delete = [];
    let has_create = false;
    let has_write = false;
    let has_delete = false;
    
    for (let i = 0; i < items.length; ++i) {
      const binType = items[i].bin_type;
      const id = items[i].id;
      const bins = items[i].bins;
      const isVisible = items[i].isVisible
    
      // Base record structure
      const record = {
        docket: docketId,
        material: items[i].material,
        number_of_240_bins: binType === '240' ? bins : 0,
        number_of_360_bins: binType === '360' ? bins : 0,
        number_of_660_bins: binType === '660' ? bins : 0
      };
    
      if (id > 0) {
        if (bins > 0 && items[i].material >= 0 && isVisible) {
          data_write.push([id]);
          data_write.push(record);
          has_write = true;
        } else {
          data_delete.push(id);
          has_delete = true;
        } 
      } else {
        if (bins > 0 && items[i].material >= 0 && isVisible) {
          data_create.push(record);
          has_create = true;
        }
      }
    }
    if (has_write) {
      data_write.push({});
    }
    // if (has_delete) {
    //   data_delete.push({});
    // }

    console.log('data_create', data_create)
    console.log('data_write', data_write)
    console.log('data_delete', data_delete)
    let created_new_data = false;
    if (data_create.length > 0) {
      const payload = {
        id: '1',
        method: 'model.prosite.waste.collection.docket.item.create',
        params: [data_create, {}],
      }
  
      try {
        const response = await axios.post(path, payload, config);
        console.log('response.data', response.data);
        if (response.data.error !== undefined) {
          return true;
        }
        created_new_data = true;    
        // return true;    
      } catch (error) {
        console.log('saveWasteCollectedDetails(create) catch:', error);
        generalMethods.messageBox('', 'Fail to save', 'error');
        return false
      }
    }
    if (data_write.length > 0) {
      const payload2 = {
        id: '1',
        method: 'model.prosite.waste.collection.docket.item.write',
        params: data_write,
      }

      try {
        const response = await axios.post(path, payload2, config);
        console.log('response.data', response.data);
        if (response.data.error !== undefined) {
          // Handle the error scenario if needed
          return true;
        }
        return true;
      } catch (error) {
        console.log('saveWasteCollectedDetails(write) catch:', error);
        generalMethods.messageBox('', 'Fail to save', 'error');
        return false        
      }     
    }
    if (data_delete.length > 0) {
      const payload = {
        id: '1',
        method: 'model.prosite.waste.collection.docket.item.delete',
        params: [data_delete, {}],
      }
  
      try {
        const response = await axios.post(path, payload, config);
        console.log('response.data', response.data);
        if (response.data.error !== undefined) {
          // Handle the error scenario if needed
          return true;
        }
        return true;
      } catch (error) {
        console.log('saveWasteCollectedDetails(delete) catch:', error);
        generalMethods.messageBox('', 'Fail to save', 'error');
        return false        
      }
      
    }
    if (created_new_data) {
      console.log('created_new_data!!!')
      await this.getBookingDetails(bookingId, false);
      return true      
    }
    return true
  },    

  async saveWasteDestination(docketId, location) {
    console.log('saveWasteDestination', docketId)

    const path = process.env.NODE_ENV === 'production' ? Configuration.value('backendHost') : 'http://localhost:8000/lawmens/'
    const sAuth1 = String('admin:') + String(rpc.getUserId()) + String(':') + String(rpc.getToken())
    const sAuth = String(btoa(sAuth1))
    const sessionAuth = String('Session ') + sAuth
    const config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: sessionAuth,
      },
    }

    const payload = {
      id: '1',
      method: 'model.prosite.waste.collection.docket.write',
      params: [[docketId], { location: location }, {}],
    }

    try {
      const response = await axios.post(path, payload, config);
      console.log('response.data', response.data);
      if (response.data.error !== undefined) {
        // Handle the error scenario if needed
        return true;
      }
      return true;
    } catch (error) {
      console.log('saveWasteDestination catch:', error);
      generalMethods.messageBox('', 'Fail to save', 'error');
      return false      
    }
  },

  async saveDocketNumber(docketId, waste_docket_number, actual_weight) {
    console.log('saveDocketNumber', docketId)

    const path = process.env.NODE_ENV === 'production' ? Configuration.value('backendHost') : 'http://localhost:8000/lawmens/'
    const sAuth1 = String('admin:') + String(rpc.getUserId()) + String(':') + String(rpc.getToken())
    const sAuth = String(btoa(sAuth1))
    const sessionAuth = String('Session ') + sAuth
    const config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: sessionAuth,
      },
    }

    const payload = {
      id: '1',
      method: 'model.prosite.waste.collection.docket.write',
      params: [[docketId], { waste_docket_number: waste_docket_number, actual_weight: actual_weight }, {}],
    }

    try {
      const response = await axios.post(path, payload, config);
      console.log('response.data', response.data);
      if (response.data.error !== undefined) {
        // Handle the error scenario if needed
        return true;
      }
      return true;
    } catch (error) {
      console.log('saveDocketNumber catch:', error);
      generalMethods.messageBox('', 'Fail to save', 'error');
      return false      
    }
  },

  async CreateAttachments(bookingId, parentid, file, filename) {
    if (filename === ""){
      return
    }
    console.log('CreateAttachments 1')  
    const path = process.env.NODE_ENV === 'production' ? Configuration.value('backendHost') : 'http://localhost:8000/lawmens/'
    const sAuth1 = String('admin:') + String(rpc.getUserId()) + String(':') + String(rpc.getToken())
    const sAuth = String(btoa(sAuth1))
    const sessionAuth = String('Session ') + sAuth
    const config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: sessionAuth,
      },
    } 

    let params = {}
    params.name = filename;   
    params.type = 'data'
    params.data = {"__class__": "bytes", "base64": file.replace('data:image/png;base64,', '')}
    params.resource = 'prosite.waste.collection.docket,'+parentid

    const payload = {
      id: 1,
      method: 'model.ir.attachment.create',
      params: [[params], {}],
    };
    try{
      const response = await axios.post(path, payload, config);
      if (response.data.result !== undefined) {
        console.log('CREATED')
        console.log(response.data.result)

        await this.getBookingDetails(bookingId, false);

      } else if (response.data.error !== undefined) {
        console.log('ERROR')
      } else {
        console.log('UNABLE')
      }
    }catch(Exception){
      console.log('Exception:'+Exception)
      return null;
    } 
  }, 

  async CreateDCAttachments(bookingId, file, filename) {
    if (filename === ""){
      return
    }
    console.log('CreateAttachments 1')  
    const path = process.env.NODE_ENV === 'production' ? Configuration.value('backendHost') : 'http://localhost:8000/lawmens/'
    const sAuth1 = String('admin:') + String(rpc.getUserId()) + String(':') + String(rpc.getToken())
    const sAuth = String(btoa(sAuth1))
    const sessionAuth = String('Session ') + sAuth
    const config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: sessionAuth,
      },
    } 

    let params = {}
    params.name = filename;   
    params.type = 'data'
    params.data = {"__class__": "bytes", "base64": file.replace('data:image/png;base64,', '')}
    params.resource = 'prosite.waste.collection,'+bookingId

    const payload = {
      id: 1,
      method: 'model.ir.attachment.create',
      params: [[params], {}],
    };
    try{
      const response = await axios.post(path, payload, config);
      if (response.data.result !== undefined) {
        console.log('CREATED')
        console.log(response.data.result)

        // await this.getDCBookingLines(bookingId, false);
        await store.dispatch('app/getDriverBooking', bookingId);

      } else if (response.data.error !== undefined) {
        console.log('ERROR')
      } else {
        console.log('UNABLE')
      }
    }catch(Exception){
      console.log('Exception:'+Exception)
      return null;
    } 
  },   

  async UpdateAttachments(fileid, file, filename) {
    console.log('UpdateAttachments 1')
    console.log('fileid', fileid)
    const path = process.env.NODE_ENV === 'production' ? Configuration.value('backendHost') : 'http://localhost:8000/lawmens/'
    const sAuth1 = String('admin:') + String(rpc.getUserId()) + String(':') + String(rpc.getToken())
    const sAuth = String(btoa(sAuth1))
    const sessionAuth = String('Session ') + sAuth
    const config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: sessionAuth,
      },
    } 

    let params = {}
    params.name = filename;   
    params.type = 'data'
    params.data = {"__class__": "bytes", "base64": file}
    params.resource = 'project.work,'+parentid

    const payload = {
      id: 1,
      method: 'model.ir.attachment.write',
      params: [ [fileid], params, {}],
    };
    try{
      const response = await axios.post(path, payload, config);
      if (response.data.result !== undefined) {
        console.log('UPDATED')
        console.log(response.data.result)
      } else if (response.data.error !== undefined) {
        console.log('ERROR')
      } else {
        console.log('UNABLE')
      }
    }catch(Exception){
      console.log('Exception:'+Exception)
      return null;
    } 
  }, 

  async deleteAttachments(bookingId, documentId) {
    console.log('deleteAttachments 1')
    const path = process.env.NODE_ENV === 'production' ? Configuration.value('backendHost') : 'http://localhost:8000/lawmens/'
    const sAuth1 = String('admin:') + String(rpc.getUserId()) + String(':') + String(rpc.getToken())
    const sAuth = String(btoa(sAuth1))
    const sessionAuth = String('Session ') + sAuth
    const config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: sessionAuth,
      },
    }    
    const web_session = localStorage.getItem('accessToken');
    let searchParams = [];
    searchParams.push(documentId)
    const filter = searchParams

    const payload = {
      id: 1,
      method: 'model.ir.attachment.delete',
      params: [filter, {}],
    };

    try{
      const response = await axios.post(path, payload, config);
      // messageBox.displayStandard('Success', 'File deleted', 'success');

      await this.getBookingDetails(bookingId, false);
    }catch(Exception){
      console.log('Exception:'+Exception)
      return null;
    } 
  },   

  async deleteDCAttachments(bookingId, documentId) {
    console.log('deleteDCAttachments 1')
    const path = process.env.NODE_ENV === 'production' ? Configuration.value('backendHost') : 'http://localhost:8000/lawmens/'
    const sAuth1 = String('admin:') + String(rpc.getUserId()) + String(':') + String(rpc.getToken())
    const sAuth = String(btoa(sAuth1))
    const sessionAuth = String('Session ') + sAuth
    const config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: sessionAuth,
      },
    }    
    const web_session = localStorage.getItem('accessToken');
    let searchParams = [];
    searchParams.push(documentId)
    const filter = searchParams

    const payload = {
      id: 1,
      method: 'model.ir.attachment.delete',
      params: [filter, {}],
    };

    try{
      const response = await axios.post(path, payload, config);
      // messageBox.displayStandard('Success', 'File deleted', 'success');

      await store.dispatch('app/getDriverBooking', bookingId);
    }catch(Exception){
      console.log('Exception:'+Exception)
      return null;
    } 
  },  

  getWasteMaterials() {
    console.log('getWasteMaterials')
    const path = process.env.NODE_ENV === 'production' ? Configuration.value('backendHost') : 'http://localhost:8000/lawmens/'
    const sAuth1 = String('admin:') + String(rpc.getUserId()) + String(':') + String(rpc.getToken())
    const sAuth = String(btoa(sAuth1))
    const sessionAuth = String('Session ') + sAuth
    const config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: sessionAuth,
      },
    }

    const offset = 0
    const payload = {
      id: '1',
      method: 'model.prosite.waste.material.search_read',
      params: [null, offset, null, null, ['name', 'ewc', 'recyclable_percentage', 'is_hazardous', 'weight_per_bin_240', 'weight_per_bin_360', 'weight_per_bin_660'], { web_user_session: rpc.getWebToken() }],
    }

    axios.post(path, payload, config)
      .then(response => {
        store.commit('app/clearWasteMaterials', []);
        
        if (response.data.error !== undefined) {
          return
        }

        if (response.data.result.length > 0) {
          console.log(response.data.result)
          store.commit('app/setWasteMaterials', response.data.result);
          return
        }

      })
      .catch(error => {
        console.log('getWasteMaterials catch:', error)
      })    
  },    
  
  getWasteLocations() {
    console.log('getWasteLocations')
    const path = process.env.NODE_ENV === 'production' ? Configuration.value('backendHost') : 'http://localhost:8000/lawmens/'
    const sAuth1 = String('admin:') + String(rpc.getUserId()) + String(':') + String(rpc.getToken())
    const sAuth = String(btoa(sAuth1))
    const sessionAuth = String('Session ') + sAuth
    const config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: sessionAuth,
      },
    }
    // const order = [['address', 'ASC']]
    const offset = 0
    const payload = {
      id: '1',
      method: 'model.prosite.waste.location.search_read',
      params: [null, offset, null, null, ['address', 'carrier', 'managed_by', 'ea_permit_number', 'address_name'], { web_user_session: rpc.getWebToken() }],
    }

    axios.post(path, payload, config)
      .then(response => {
        store.commit('app/clearWasteLocations', []);
        
        if (response.data.error !== undefined) {
          return
        }

        if (response.data.result.length > 0) {
          console.log(response.data.result)
          store.commit('app/setWasteLocations', response.data.result);
          return
        }

      })
      .catch(error => {
        console.log('getWasteLocations catch:', error)
      })    
  },

// Delivery Collection

  async getDCBookingLines(bookingId, go_to_veh_reg = true) {
    console.log('getDCBookingLines', bookingId)
    const path = process.env.NODE_ENV === 'production' ? Configuration.value('backendHost') : 'http://localhost:8000/lawmens/'
    const sAuth1 = String('admin:') + String(rpc.getUserId()) + String(':') + String(rpc.getToken())
    const sAuth = String(btoa(sAuth1))
    const sessionAuth = String('Session ') + sAuth
    const config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: sessionAuth,
      },
    }

    const filter = ['collection', '=', bookingId]
    const offset = 0

    const payload = {
      id: '1',
      method: 'model.prosite.waste.collection.line.search_read',
      params: [[filter], offset, null, null, ['collection', 'quantity', 'notes', 'is_collection', 'type.name', 'type.category', 'type.trackable_by_quantity'], { web_user_session: rpc.getWebToken() }],
    }

    axios.post(path, payload, config)
      .then(response => {
        store.commit('app/clearDCBookingLines', []);

        if (response.data.error !== undefined) {
          return
        }

        //if (response.data.result.length > 0) {
        if (response.data.result) {          
          console.log(response.data.result)
          store.commit('app/setDCBookingLines', response.data.result);
          if (go_to_veh_reg) {
            router.push('/items_to_deliver_dc');
          }
          return
        }

      })
      .catch(error => {
        console.log('getDCBookingLines catch:', error)
      })    
  },  

  async createBookingLines(bookingId) {
    console.log('createBookingLines', bookingId)

    const path = process.env.NODE_ENV === 'production' ? Configuration.value('backendHost') : 'http://localhost:8000/lawmens/'
    const sAuth1 = String('admin:') + String(rpc.getUserId()) + String(':') + String(rpc.getToken())
    const sAuth = String(btoa(sAuth1))
    const sessionAuth = String('Session ') + sAuth
    const config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: sessionAuth,
      },
    }

    const payload = {
      id: '1',
      method: 'model.prosite.waste.collection.line.create',
      params: [[{ collection: bookingId, quantity: 0, is_collection: false }, { collection: bookingId, quantity: 0, is_collection: true }], {}],
    }

    try {
      const response = await axios.post(path, payload, config);
      console.log('response.data', response.data);
      if (response.data.error !== undefined) {
        return;
      }
      await this.saveBooking(bookingId);
      await this.getDCBookingLines(bookingId);
    } catch (error) {
      console.log('createBookingLines catch:', error);
    }
  },  


  async saveBookingLines(bookingId, items, is_collection) {
    console.log('saveBookingLines', items)

    const path = process.env.NODE_ENV === 'production' ? Configuration.value('backendHost') : 'http://localhost:8000/lawmens/'
    const sAuth1 = String('admin:') + String(rpc.getUserId()) + String(':') + String(rpc.getToken())
    const sAuth = String(btoa(sAuth1))
    const sessionAuth = String('Session ') + sAuth
    const config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: sessionAuth,
      },
    }

    let data_create = [];
    let data_write = [];
    let data_delete = [];
    let has_create = false;
    let has_write = false;
    let has_delete = false;
    
    for (let i = 0; i < items.length; ++i) {
      if (items[i].is_collection != is_collection){
        continue;
      }
      const id = items[i].id;
      const quantity = items[i].quantity;
      const type = items[i].type;
      const notes = items[i].notes;
      const isVisible = items[i].isVisible;
    
      // Base record structure
      const record = {
        collection: bookingId,
        is_collection: is_collection,
        quantity: quantity,
        notes: notes,
        type: type.id,
      };
    
      if (id > 0) {
        if ((quantity > 0 || notes != null) && items[i].type.id >= 0 && isVisible) {
          data_write.push([id]);
          data_write.push(record);
          has_write = true;
        } else {
          data_delete.push(id);
          has_delete = true;
        } 
      } else {
        if ((quantity > 0 || notes != null) && items[i].type.id >= 0 && isVisible) {
          data_create.push(record);
          has_create = true;
        }
      }
    }
    if (has_write) {
      data_write.push({});
    }

    console.log('data_create', data_create)
    console.log('data_write', data_write)
    console.log('data_delete', data_delete)
    let created_new_data = false;
    let updated_new_data = false;
    if (data_create.length > 0) {
      const payload = {
        id: '1',
        method: 'model.prosite.waste.collection.line.create',
        params: [data_create, {}],
      }
  
      try {
        const response = await axios.post(path, payload, config);
        console.log('response.data', response.data);
        if (response.data.error !== undefined) {
          return;
        }
        created_new_data = true;        
      } catch (error) {
        console.log('saveBookingLines(create) catch:', error);
      }
    }
    if (data_write.length > 0) {
      const payload2 = {
        id: '1',
        method: 'model.prosite.waste.collection.line.write',
        params: data_write,
      }

      try {
        const response = await axios.post(path, payload2, config);
        console.log('response.data', response.data);
        if (response.data.error !== undefined) {
          return;
        }
        updated_new_data = true;        
      } catch (error) {
        console.log('saveBookingLines(write) catch:', error);
      }

      // axios.post(path, payload2, config)
      //   .then(response => {
      //     console.log('response.data', response.data)
      //     if (response.data.error !== undefined) {
      //       return
      //     }
      //     updated_new_data = true;
      //   })
      //   .catch(error => {
      //     console.log('saveBookingLines(write) catch:', error)
      //   })      
    }
    if (data_delete.length > 0) {
      const payload = {
        id: '1',
        method: 'model.prosite.waste.collection.line.delete',
        params: [data_delete, {}],
      }
  
      try {
        const response = await axios.post(path, payload, config);
        console.log('response.data', response.data);
        if (response.data.error !== undefined) {
          return;
        }
        updated_new_data = true;        
      } catch (error) {
        console.log('saveBookingLines(delete) catch:', error);
      }

      // axios.post(path, payload, config)
      //   .then(response => {
      //     console.log('response.data', response.data)
      //     if (response.data.error !== undefined) {
      //       return
      //     }
      //   })
      //   .catch(error => {
      //     console.log('saveBookingLines(delete) catch:', error)
      //   })
    }
    if (created_new_data || updated_new_data) {
      console.log('created_new_data!!!')
      await this.getDCBookingLines(bookingId, false);
    }
  },    

  async saveCollectionSignatureDetails(bookingId, client_print_name, client_signature) {
    console.log('saveCollectionSignatureDetails', bookingId)

    const path = process.env.NODE_ENV === 'production' ? Configuration.value('backendHost') : 'http://localhost:8000/lawmens/'
    const sAuth1 = String('admin:') + String(rpc.getUserId()) + String(':') + String(rpc.getToken())
    const sAuth = String(btoa(sAuth1))
    const sessionAuth = String('Session ') + sAuth
    const config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: sessionAuth,
      },
    }
    if (client_signature) {
      let signature = {"__class__": "bytes", "base64": client_signature.replace('data:image/png;base64,', '')}
      const payload = {
        id: '1',
        method: 'model.prosite.waste.collection.write',
        params: [[bookingId], { print_name: client_print_name, signature: signature }, {}],
      }
      axios.post(path, payload, config)
      .then(response => {
        console.log('response.data', response.data)
        if (response.data.error !== undefined) {
          return
        }
      })
      .catch(error => {
        console.log('saveCollectionSignatureDetails catch:', error)
      })   
    } else {
      const payload = {
        id: '1',
        method: 'model.prosite.waste.collection.write',
        params: [[bookingId], { client_print_name: client_print_name }, {}],
      }
      axios.post(path, payload, config)
      .then(response => {
        console.log('response.data', response.data)
        if (response.data.error !== undefined) {
          return
        }
      })
      .catch(error => {
        console.log('saveCollectionSignatureDetails catch:', error)
      })      
    }
  }, 

  async saveCollectionItemsToCollect(bookingId, items_to_collect) {
    console.log('saveCollectionItemsToCollect', bookingId)

    const path = process.env.NODE_ENV === 'production' ? Configuration.value('backendHost') : 'http://localhost:8000/lawmens/'
    const sAuth1 = String('admin:') + String(rpc.getUserId()) + String(':') + String(rpc.getToken())
    const sAuth = String(btoa(sAuth1))
    const sessionAuth = String('Session ') + sAuth
    const config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: sessionAuth,
      },
    }
      const payload = {
        id: '1',
        method: 'model.prosite.waste.collection.write',
        params: [[bookingId], { items_to_collect: items_to_collect }, {}],
      }
      axios.post(path, payload, config)
      .then(response => {
        console.log('response.data', response.data)
        if (response.data.error !== undefined) {
          return
        }
      })
      .catch(error => {
        console.log('saveCollectionItemsToCollect catch:', error)
      })  
  }, 
  async saveCollectionItemsToDeliver(bookingId, items_to_deliver) {
    console.log('saveCollectionItemsToDeliver', bookingId)

    const path = process.env.NODE_ENV === 'production' ? Configuration.value('backendHost') : 'http://localhost:8000/lawmens/'
    const sAuth1 = String('admin:') + String(rpc.getUserId()) + String(':') + String(rpc.getToken())
    const sAuth = String(btoa(sAuth1))
    const sessionAuth = String('Session ') + sAuth
    const config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: sessionAuth,
      },
    }
      const payload = {
        id: '1',
        method: 'model.prosite.waste.collection.write',
        params: [[bookingId], { items_to_deliver: items_to_deliver }, {}],
      }
      axios.post(path, payload, config)
      .then(response => {
        console.log('response.data', response.data)
        if (response.data.error !== undefined) {
          return
        }
      })
      .catch(error => {
        console.log('saveCollectionItemsToDeliver catch:', error)
      })  
  },   

  async saveDCNotes(bookingId, notes) {
    console.log('saveDCNotes', bookingId)

    const path = process.env.NODE_ENV === 'production' ? Configuration.value('backendHost') : 'http://localhost:8000/lawmens/'
    const sAuth1 = String('admin:') + String(rpc.getUserId()) + String(':') + String(rpc.getToken())
    const sAuth = String(btoa(sAuth1))
    const sessionAuth = String('Session ') + sAuth
    const config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: sessionAuth,
      },
    }

    const payload = {
      id: '1',
      method: 'model.prosite.waste.collection.write',
      params: [[bookingId], { reason_notes: notes }, {}],
    }

    axios.post(path, payload, config)
      .then(response => {
        console.log('response.data', response.data)
        if (response.data.error !== undefined) {
          return
        }
      })
      .catch(error => {
        console.log('saveDCNotes catch:', error)
      }) 
  },

  async saveDCItemsToCollect(bookingId, items_to_collect) {
    console.log('saveDCItemsToCollect', bookingId)

    const path = process.env.NODE_ENV === 'production' ? Configuration.value('backendHost') : 'http://localhost:8000/lawmens/'
    const sAuth1 = String('admin:') + String(rpc.getUserId()) + String(':') + String(rpc.getToken())
    const sAuth = String(btoa(sAuth1))
    const sessionAuth = String('Session ') + sAuth
    const config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: sessionAuth,
      },
    }

    const payload = {
      id: '1',
      method: 'model.prosite.waste.collection.write',
      params: [[bookingId], { items_to_collect: items_to_collect }, {}],
    }

    axios.post(path, payload, config)
      .then(response => {
        console.log('response.data', response.data)
        if (response.data.error !== undefined) {
          return
        }
      })
      .catch(error => {
        console.log('saveDCItemsToCollect catch:', error)
      }) 
  },

  getDCLineTypes() {
    console.log('getDCLineTypes')
    const path = process.env.NODE_ENV === 'production' ? Configuration.value('backendHost') : 'http://localhost:8000/lawmens/'
    const sAuth1 = String('admin:') + String(rpc.getUserId()) + String(':') + String(rpc.getToken())
    const sAuth = String(btoa(sAuth1))
    const sessionAuth = String('Session ') + sAuth
    const config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: sessionAuth,
      },
    }

    const offset = 0
    const payload = {
      id: '1',
      method: 'model.prosite.waste.collection.line.type.search_read',
      params: [null, offset, null, null, ['name', 'category', 'trackable_by_quantity'], { web_user_session: rpc.getWebToken() }],
    }

    axios.post(path, payload, config)
      .then(response => {
        store.commit('app/clearDCLineTypes', []);
        
        if (response.data.error !== undefined) {
          return
        }

        if (response.data.result.length > 0) {
          console.log(response.data.result)
          store.commit('app/setDCLineTypes', response.data.result);
          return
        }

      })
      .catch(error => {
        console.log('getDCLineTypes catch:', error)
      })    
  }, 

}
/* eslint-enable */
