<template>
  <div class="oci-check-in-out-body"> 
    <b-card class="oci-check-in-out-card">     
        <b-row>      
          <b-col cols="4">
            Job:
          </b-col>
          <b-col>
            {{ site.jobNumber }}
          </b-col>     
        </b-row>       
        <b-row>
          <b-col cols="4">
            Open:
          </b-col>
          <b-col>
            {{ site.openTime + ' - ' + site.closeTime }}
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4">
            Client:
          </b-col>
          <b-col>
            {{ site.name }}
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4">
            Address:
          </b-col>
          <b-col>
            {{ site.address }}&nbsp;
            <b-link :href="getPostCodeLink(site.postcode)">
              {{ site.postcode }}
            </b-link>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4">
            Contact:
          </b-col>
          <b-col>
            {{ site.contactName }}<br>
            <b-link :href="getPhoneLink(site.contactPhone)">
              {{ site.contactPhone }}
            </b-link>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4">
            Task:
          </b-col>
          <b-col>
            {{ task }}
          </b-col>
        </b-row>           
    </b-card>
    <CheckInOutButton :is-map="false" />
    <div v-if="this.checkedInStatus !== 'in'">
      <UnableToAttendButton :is-map="false" />
    </div>
    <div v-if="this.checkedInStatus === 'in'">
      <CancelSignedOutButton :is-map="false" />
    </div>    
  </div>   
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BButton, BLink,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import CheckInOutButton from '@/components/ui/CheckInOutButton.vue'
import UnableToAttendButton from '@/components/ui/UnableToAttendButton.vue'
import CancelSignedOutButton from '@/components/ui/CancelSignedOutButton.vue'
import RejectButton from '@/components/ui/RejectButton.vue'
import ProjectsButton from '@/components/ui/ProjectsButton.vue'
import store from '@/store'

export default {
  name: 'CheckInOut',
  components: {
    CheckInOutButton,
    UnableToAttendButton,
    CancelSignedOutButton,
    RejectButton,
    ProjectsButton,
    BRow,
    BCol,
    BCard,
    BButton,
    BLink,
  },
  computed: {
    ...mapGetters({ site: 'app/getCurrentSiteDetails' }),
    ...mapGetters({ task: 'app/getCurrentTask' }),
    ...mapGetters({ userAssignmentSet: 'app/getUserAssignmentSet' }),
    ...mapGetters({ totalAssignments: 'app/getTotalAssignments' }),    
    ...mapGetters({ oneAlreadyCheckedIn: 'app/getOneAlreadyCheckedIn' }),  
    ...mapGetters({ checkedInStatus: 'app/getCheckedInStatus' }),  
    ...mapGetters({ isCheckInOut: 'app/getIsCheckInOut' }),     
    ...mapGetters({ isFromProjectListPage: 'app/getIsFromProjectListPage' }),    
    ...mapGetters({ previousAssignmentsID: 'app/getPreviousAssignmentsID' }), 
  },
  methods: {
    getPostCodeLink(postCode) {
      return `https://maps.google.com/maps?q=${postCode}`
    },
    getPhoneLink(phoneNo) {
      return `tel:${phoneNo}`
    },
    GoToBackAssignmentListPage() {
      store.commit('app/setIsCheckInOut', true);
      if (this.isFromProjectListPage){
        store.commit('app/changeUserAssignmentsID', this.previousAssignmentsID)
        store.commit('app/setIsFromProjectListPage', false);
        this.$router.go(-1)
        return
      }
      store.commit('app/setIsFromProjectListPage', false);
      store.commit('app/setUserAssignmentSet', false)      
      // this.$router.go(-1)
    },
  },
}
</script>
