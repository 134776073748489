// import Vue from 'vue'
import axios from 'axios'
import { messageBox } from './MessageBox'
import rpc from '@/auth/rpc/ociRpc'
import store from '@/store'
import router from '@/router'
import { generalMethods } from '@/components/General-Functions'
import Configuration from '@/utils/configuration'
import { DeviceUUID } from "device-uuid";
// import { stringify } from 'uuid'

function getEventTime() {
  const dateTime = new Date()
  return {
    __class__: 'datetime',
    year: dateTime.getFullYear(),
    month: dateTime.getMonth() + 1,
    day: dateTime.getDate(),
    hour: dateTime.getHours(),
    minute: dateTime.getMinutes(),
    second: dateTime.getSeconds(),
    microsecond: dateTime.getMilliseconds(),
  }
}
/* eslint-disable */
export const checkInOutMethods = {
  path: process.env.NODE_ENV === 'production' ? Configuration.value('backendHost') : 'http://localhost:8000/lawmens/',
  loginUser: '',
  loginPw: '',
  loginID: '',
  loginToken: '',
  webToken: '',
  userDetails: '',
  userAssignmentID: -1,
  wizardID: '',
  sessionAuth: '',
  longitude: 0,
  latitude: 0,
  accuracy: 0,
  event_time_pc: null,
  callback: null,

  reset() {
    this.siteOperativeId = '';
    this.loginUser = '';
    this.loginPw = '';
    this.loginID = '';
    this.loginToken = '';
    this.webToken = '';
    this.userDetails = '';
    this.userAssignmentID = -3;
    this.wizardID = '';
    this.sessionAuth = ''; 
    this.longitude = 0;
    this.latitude = 0;
    this.accuracy = 0;
    this.event_time_pc = null;
  },

  setSessionAuth() {
    const sAuth1 = String('admin:') + String(this.loginID) + String(':') + String(this.loginToken);
    const sAuth = String(btoa(sAuth1));
    this.sessionAuth = String('Session ') + sAuth;
  },

  async CheckIn(siteOperativeId, latitude, longitude, accuracy) {
    console.log('CheckIn')
    this.siteOperativeId = siteOperativeId;
    this.longitude = longitude;
    this.latitude = latitude;
    this.accuracy = accuracy;
    this.loginID = rpc.getUserId();
    this.loginToken = rpc.getToken();
    this.setSessionAuth();
    this.userDetails = store.getters['app/getUserDetails'];
    this.userAssignmentID = store.getters['app/getUserAssignmentsID'];
    console.log('this.userAssignmentID', this.userAssignmentID)
    if (this.userAssignmentID < 0){
      try{
        console.log('getTodaysJobsAssignId')
        // await this.getTodaysJobsAssignId();
        this.userAssignmentID = store.getters['app/getUserAssignmentsID'];
      }catch(Exception){
        console.log('Error: getTodaysJobsAssignId')
      }
    }
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: this.sessionAuth,
      },      
      timeout: 120000,
    };
    const params = this.userDetails;
    // console.log('params: '+params)
    const payload = {
      // jsonrpc: '1.0',
      id: '1',
      method: 'wizard.project.attendance.sign_in.create',
      params: [{ params }],
    };

    try{
      const response = await axios.post(this.path, payload, config);
      if (response.data['result'] !== undefined) {
        const iterator = response.data['result'].values();
        this.wizardID = iterator.next().value;
        await this.CheckInExecute();
      } else if (response.data['error'] !== undefined) {
        messageBox.displayErrorFromData(response.data);
      } else {
        messageBox.displayStandard('Error', 'Unable to Sign in at this time [Create failed]', 'error');
      }      
    }catch(Exception){
      messageBox.displayStandard('Error', Exception, 'error');
      console.log('Error: CheckIn - '+ Exception)
    }  

    /*
    axios.post("http://localhost:8000/lawmens/", payload, config)
      .then(response => {
        console.log('.then response')
        console.log(response)
        
        if (response.data['result'] !== undefined) {
          const iterator = response.data['result'].values();
          this.wizardID = iterator.next().value;
          this.CheckInExecute();
          console.log('after CheckInExecute')
          return;
        } else if (response.data['error'] !== undefined) {
          messageBox.displayErrorFromData(response.data);
        } else {
          messageBox.displayStandard('Error', 'Unable to Sign in at this time [Create failed]', 'error');
        }
        
      });
      */
  },

  async CheckInExecute() {
    this.webToken = rpc.getWebToken();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
        'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS, post, get',
        'Access-Control-Allow-Credentials': 'true',
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: this.sessionAuth,
      },
    };
    const payload = {
      // jsonrpc: '1.0',
      id: '1',
      method: 'wizard.project.attendance.sign_in.execute',
      params: [this.wizardID, {}, 'start', { 'web_user_session': this.webToken, 'active_id': this.userAssignmentID, 'active_ids': [this.userAssignmentID], 'active_model': 'project.site.assignment' }]
    };
    
    try{
      const response = await axios.post(this.path, payload, config);
      if (response){
        if (response.data['result'] !== undefined) {
          this.event_time_pc = response.data['result']['view']['defaults']['event_time']
          await this.CheckInExecuteCheckIn();
        } else if (response.data['error'] !== undefined) {
          messageBox.displayErrorFromData(response.data);
        } else {
          messageBox.displayStandard('Error', 'Unable to Sign in at this time [Execute failed]', 'error');
        }    
      }
    }catch(Exception){
      console.log('Error: CheckInExecute - '+ Exception)
    }   
    /*  
      .then((response) => {
        if (response.data['result'] !== undefined) {
          this.event_time_pc = response.data['result']['view']['defaults']['event_time']
          await this.CheckInExecuteCheckIn();
        } else if (response.data['error'] !== undefined) {
          messageBox.displayErrorFromData(response.data);
        } else {
          messageBox.displayStandard('Error', 'Unable to Sign in at this time [Execute failed]', 'error');
        }
      })
      .catch((error) => {
        console.log(error.request);
      });
      */
  },      
  async CheckInExecuteCheckIn() {
    console.log('CheckInExecuteCheckIn')
    const config = {
      headers: {
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin': '*',
        // 'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
        // 'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS, post, get',
        // 'Access-Control-Allow-Credentials': 'true',
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: this.sessionAuth,
      },
    };
    var device_id = '0'
    try{
      // device_id = new DeviceUUID().get(); #LL
      // localStorage.setItem("DEVICEID","123456789")
      device_id = localStorage.getItem("OCIDEVICEID")
      console.log(device_id)
    }catch(Exception){
      device_id = 'DEVICE_ID'
    }

    const payload = {
      // jsonrpc: '1.0',
      id: '1',
      method: 'wizard.project.attendance.sign_in.execute',
      params: [this.wizardID, {details: {'assignment': this.userAssignmentID, 'latitude': this.latitude, 'longitude': this.longitude, 'accuracy': this.accuracy, 'device': device_id, 'event_time': this.event_time_pc}}, 'create_event_details', {'web_user_session': this.webToken, 'active_id': this.userAssignmentID, 'active_ids': [this.userAssignmentID], 'active_model': 'project.site.assignment'}]
    };

    try{
      const response = await axios.post(this.path, payload, config);
      if (response){
        if (response.data['result'] !== undefined) {
          store.commit('app/updateCheckedInStatus', 'in');
          generalMethods.messageBox('', `You have signed in at ${generalMethods.getFormattedTime()}`, 'success');
          store.commit('app/setSignInTime', generalMethods.getFormattedTime());
          await this.CheckInWizardDelete();
        } else if (response.data['error'] !== undefined) {
          messageBox.displayErrorFromData(response.data);
        } else {
          messageBox.displayStandard('Error', 'Unable to Sign in at this time [Sign in failed]', 'error');
        }  
      }
    }catch(Exception){
      console.log('Error: CheckInExecuteCheckIn - '+ Exception)
    }    
    /*
    await axios.post(this.path, payload, config)
      .then((response) => {
        // console.log(response.data)
        if (response.data['result'] !== undefined) {
          store.commit('app/updateCheckedInStatus', 'in');
          generalMethods.messageBox('', `You have signed in at ${generalMethods.getFormattedTime()}`, 'success');
          store.commit('app/setSignInTime', generalMethods.getFormattedTime());
          await this.CheckInWizardDelete();
        } else if (response.data['error'] !== undefined) {
          messageBox.displayErrorFromData(response.data);
        } else {
          messageBox.displayStandard('Error', 'Unable to Sign in at this time [Sign in failed]', 'error');
        }
      })
      .catch((error) => {
        console.log(error);
      });
      */
  },
  async CheckInWizardDelete(){
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: this.sessionAuth,
      },
    };
    const params = this.userDetails;
    const payload = {
      // jsonrpc: '1.0',
      id: '1',
      method: 'wizard.project.attendance.sign_in.delete',
      params: [this.wizardID, { params }],
    };

    try{
      const response = await axios.post(this.path, payload, config);
      if (response){
        if (response.data['result'] !== undefined) {
          router.push('/');
        } else if (response.data['error'] !== undefined) {
          messageBox.displayErrorFromData(response.data);
        } else {
          messageBox.displayStandard('Error', 'Unable to Sign in at this time [Sign in wizard failed]', 'error');
        }
      }
    }catch(Exception){
      console.log('Error: CheckInWizardDelete - '+ Exception)
    } 
    /*
    await axios.post(this.path, payload, config)
      .then((response) => {        
        if (response.data['result'] !== undefined) {
          router.push('/');
        } else if (response.data['error'] !== undefined) {
          messageBox.displayErrorFromData(response.data);
        } else {
          messageBox.displayStandard('Error', 'Unable to Sign in at this time [Sign in wizard failed]', 'error');
        }
      })
      .catch((error) => {
        console.log(error);
      });
      */
  },


  async CheckOut(siteOperativeId, latitude, longitude, accuracy, callback) {
    this.siteOperativeId = siteOperativeId;
    this.longitude = longitude;
    this.latitude = latitude;
    this.accuracy = accuracy;
    this.callback = callback;
    this.loginID = rpc.getUserId();
    this.loginToken = rpc.getToken();
    this.setSessionAuth();
    this.userDetails = store.getters['app/getUserDetails'];
    this.userAssignmentID = store.getters['app/getUserAssignmentsID'];

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: this.sessionAuth,
      },      
      timeout: 120000,
    };
    const params = this.userDetails;
    const payload = {
      // jsonrpc: '1.0',
      id: '1',
      method: 'wizard.project.attendance.sign_out.create',
      params: [{ params }],
    };

    try{
      const response = await axios.post(this.path, payload, config)
      if (response) {
        if (response.data['result'] !== undefined) {
          const iterator = response.data['result'].values();
          this.wizardID = iterator.next().value;
          await this.CheckOutExecute();
        } else if (response.data['error'] !== undefined) {
          messageBox.displayErrorFromData(response.data);
        } else {
          messageBox.displayStandard('Error', 'Unable to Sign out at this time [Create failed]', 'error');
        }      
      }else{
        store.commit('app/setIsLoadingAssigments', false);
      }
    }catch(Exception){
      messageBox.displayStandard('Error', Exception, 'error');
      console.log('Error: CheckOut - '+ Exception);
    }
    /*
    axios.post(this.path, payload, config)
      .then((response) => {
        if (response.data['result'] !== undefined) {
          const iterator = response.data['result'].values();
          this.wizardID = iterator.next().value;
          this.CheckOutExecute();
        } else if (response.data['error'] !== undefined) {
          messageBox.displayErrorFromData(response.data);
        } else {
          messageBox.displayStandard('Error', 'Unable to Sign out at this time [Create failed]', 'error');
        }
      })
      .catch((error) => {
        store.commit('app/setIsLoadingAssigments', false);
        console.log(error);
      });
      */
  },
  async CheckOutExecute() {
    this.webToken = rpc.getWebToken();
    
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: this.sessionAuth,
      },
    };
    const payload = {
      // jsonrpc: '1.0',
      id: '1',
      method: 'wizard.project.attendance.sign_out.execute',
      params: [this.wizardID, {}, 'start', { 'web_user_session': this.webToken, 'active_id': this.userAssignmentID, 'active_ids': [this.userAssignmentID], 'active_model': 'project.site.assignment' }]
    };

    try{
      const response = await axios.post(this.path, payload, config)
      if (response) {
        if (response.data['result'] !== undefined) {
          this.event_time_pc = response.data['result']['view']['defaults']['event_time']
          this.CheckOutExecuteCheckOut();
        } else if (response.data['error'] !== undefined) {
          messageBox.displayErrorFromData(response.data);
        } else {
          messageBox.displayStandard('Error', 'Unable to Sign out at this time [Execute failed]', 'error');
        }
      }
    }catch(Exception){
      console.log('Error: CheckOutExecute - '+ Exception)
    }
    /*
    axios.post(this.path, payload, config)
      .then((response) => {
        if (response.data['result'] !== undefined) {
          this.event_time_pc = response.data['result']['view']['defaults']['event_time']
          this.CheckOutExecuteCheckOut();
        } else if (response.data['error'] !== undefined) {
          messageBox.displayErrorFromData(response.data);
        } else {
          messageBox.displayStandard('Error', 'Unable to Sign out at this time [Execute failed]', 'error');
        }
      })
      .catch((error) => {
        store.commit('app/setIsLoadingAssigments', false);
        console.log(error)
      });
      */
  },
  async CheckOutExecuteCheckOut() {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: this.sessionAuth,
      },
    };
    // const event_time = getEventTime();
/*
    var device_id = '0'
    try{
      device_id = new DeviceUUID().get();
    }catch(Exception){
      device_id = 'DEVICE_ID'
    }
*/
    var device_id = '0'
    try{
      device_id = localStorage.getItem("OCIDEVICEID")
      console.log(device_id)
    }catch(Exception){
      device_id = 'DEVICE_ID'
    }

    const payload = {
      // jsonrpc: '1.0',
      id: '1',
      method: 'wizard.project.attendance.sign_out.execute',
      params: [this.wizardID, {details: {'assignment': this.userAssignmentID, 'latitude': this.latitude, 'longitude': this.longitude, 'accuracy': this.accuracy, 'device': device_id, 'event_time': this.event_time_pc}}, 'create_event_details', {'web_user_session': this.webToken, 'active_id': this.userAssignmentID, 'active_ids': [this.userAssignmentID], 'active_model': 'project.site.assignment'}]
    };

    try{
      const response = await axios.post(this.path, payload, config)
      if (response) {
        if (response.data['result'] !== undefined) {
          store.commit('app/updateCheckedInStatus', 'out');
          let msg = String(response.data.result.view.defaults.message)
          if (msg.includes("too far")){
            generalMethods.messageBox(response.data.result.view.defaults.message, response.data.result.view.defaults.description, 'warning');
          }else{
            // generalMethods.messageBox(response.data.result.view.defaults.message, response.data.result.view.defaults.description, 'success');
            generalMethods.messageBox('', `You have signed out at ${generalMethods.getFormattedTime()}`, 'success');
          }
          store.commit('app/setIsLoadingAssigments', true);
          // generalMethods.messageBox('', `You have checked-out at ${generalMethods.getFormattedTime()}`, 'success');
          this.CheckOutWizardDelete();
        } else if (response.data['error'] !== undefined) {
          // generalMethods.messageBox('Your have checked-out too far', `Please remember to check-out just as you leave the site next time.`, 'warning');
          messageBox.displayErrorFromData(response.data);
        } else {
          messageBox.displayStandard('Error', 'Unable to Sign out at this time [Sign out failed]', 'error');
        }
      }
    }catch(Exception){
      console.log('Error: CheckOutExecuteCheckOut - '+ Exception)
    }
    /*
    axios.post(this.path, payload, config)
      .then((response) => {
        if (response.data['result'] !== undefined) {
          store.commit('app/updateCheckedInStatus', 'out');
          let msg = String(response.data.result.view.defaults.message)
          if (msg.includes("too far")){
            generalMethods.messageBox(response.data.result.view.defaults.message, response.data.result.view.defaults.description, 'warning');
          }else{
            // generalMethods.messageBox(response.data.result.view.defaults.message, response.data.result.view.defaults.description, 'success');
            generalMethods.messageBox('', `You have signed out at ${generalMethods.getFormattedTime()}`, 'success');
          }
          store.commit('app/setIsLoadingAssigments', true);
          // generalMethods.messageBox('', `You have checked-out at ${generalMethods.getFormattedTime()}`, 'success');
          this.CheckOutWizardDelete();
        } else if (response.data['error'] !== undefined) {
          // generalMethods.messageBox('Your have checked-out too far', `Please remember to check-out just as you leave the site next time.`, 'warning');
          messageBox.displayErrorFromData(response.data);
        } else {
          messageBox.displayStandard('Error', 'Unable to Sign out at this time [Sign out failed]', 'error');
        }
      })
      .catch((error) => {
        store.commit('app/setIsLoadingAssigments', false);
        console.log(error);
      });
      */
  },
  async CheckOutWizardDelete() {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: this.sessionAuth,
      },
    };
    const params = this.userDetails;
    const payload = {
      id: '1',
      method: 'wizard.project.attendance.sign_out.delete',
      params: [this.wizardID, { params }],
    };

    try{
      const response = await axios.post(this.path, payload, config)
      if (response) {
        if (response.data['result'] !== undefined) {
          if (this.callback) this.callback();
          router.push('/');
        } else if (response.data['error'] !== undefined) {
          if (this.callback) this.callback();
          messageBox.displayErrorFromData(response.data);
        } else {
          store.commit('app/setIsLoadingAssigments', false);
          messageBox.displayStandard('Error', 'Unable to Sign out at this time [Sign out wizard failed]', 'error');
        }
      }
    }catch(Exception){
      console.log('Error: CheckOutWizardDelete - '+ Exception)
    }

    /*
    axios.post(this.path, payload, config)
      .then((response) => {
        if (response.data['result'] !== undefined) {
          if (this.callback) this.callback();
          router.push('/');
        } else if (response.data['error'] !== undefined) {
          if (this.callback) this.callback();
          messageBox.displayErrorFromData(response.data);
        } else {
          store.commit('app/setIsLoadingAssigments', false);
          messageBox.displayStandard('Error', 'Unable to Sign out at this time [Sign out wizard failed]', 'error');
        }
      })
      .catch((error) => {
        store.commit('app/setIsLoadingAssigments', false);
        console.log(error);
      });
      */
  },

  Accept(assignmentId) {
    const path = process.env.NODE_ENV === 'production' ? Configuration.value('backendHost') : 'http://localhost:8000/lawmens/'
    const sAuth1 = String('admin:') + String(rpc.getUserId()) + String(':') + String(rpc.getToken())
    const sAuth = String(btoa(sAuth1))
    const sessionAuth = String('Session ') + sAuth
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: sessionAuth,
      },
    }

    const payload = {
      id: '1',
      // method: 'model.site.operative.assignment.accept',
      method: 'model.project.site.assignment.accept',
      params: [[assignmentId], { web_user_session: rpc.getWebToken() }],
    }  
    axios.post(path, payload, config)
    .then(response => {
      if (response.data.error) {
        generalMethods.messageBox('', 'Accept failed', 'error');
        router.push('/projects');
        return
      }

      if (response.data.result == null) {
        generalMethods.messageBox('', 'You have accepted this project', 'success');
        router.push('/projects');
        return
      }

      if (response.data.result.length > 0) {
        console.log(response.data.result)
        router.push('/projects');
        // messageBox.displayStandard('Message', 'You have accepted this project', 'message');
        return
      }

    })
    .catch(error => {
      console.log('Accept catch:', error)
    })
  },  

  Reject(assignmentId) {
    const path = process.env.NODE_ENV === 'production' ? Configuration.value('backendHost') : 'http://localhost:8000/lawmens/'
    const sAuth1 = String('admin:') + String(rpc.getUserId()) + String(':') + String(rpc.getToken())
    const sAuth = String(btoa(sAuth1))
    const sessionAuth = String('Session ') + sAuth
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: sessionAuth,
      },
    }

    const payload = {
      id: '1',
      // method: 'model.site.operative.assignment.reject',
      method: 'model.project.site.assignment.reject',
      params: [[assignmentId], { web_user_session: rpc.getWebToken() }],
    }  

    axios.post(path, payload, config)
    .then(response => {

      if (response.data.error) {
        generalMethods.messageBox('', 'Reject failed', 'error');
        router.push('/projects');
        return
      }

      if (response.data.result == null) {
        generalMethods.messageBox('', 'You have rejected this project', 'success');
        router.push('/projects');
        return
      }

      if (response.data.result.length > 0) {
        console.log(response.data.result)
        // messageBox.displayStandard('Message', 'You have rejected this project', 'message');
        router.push('/projects');
        return
      }

    })
    .catch(error => {
      console.log('Reject catch:', error)
    })
  },   

  UnableToAttend(assignmentId, reason) {
    const path = process.env.NODE_ENV === 'production' ? Configuration.value('backendHost') : 'http://localhost:8000/lawmens/'
    const sAuth1 = String('admin:') + String(rpc.getUserId()) + String(':') + String(rpc.getToken())
    const sAuth = String(btoa(sAuth1))
    const sessionAuth = String('Session ') + sAuth
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: sessionAuth,
      },
    }

    const payload = {
      id: '1',
      // method: 'model.site.operative.assignment.reject',
      method: 'model.project.site.assignment.unable_to_attend',
      params: [[assignmentId], reason, { web_user_session: rpc.getWebToken() }],
    }  

    axios.post(path, payload, config)
    .then(response => {

      if (response.data.error) {
        generalMethods.messageBox('', 'Unnable to attend failed', 'error');
        return
      }

      if (response.data.result == null) {
        generalMethods.messageBox('', 'Thank you for letting us know', 'success');
        return
      }

      if (response.data.result.length > 0) {
        console.log(response.data.result)
        // messageBox.displayStandard('Message', 'You have rejected this project', 'message');
        return
      }

    })
    .catch(error => {
      console.log('Unaccept catch:', error)
    })
  }, 

  CancelSignIn(assignmentId) {
    const path = process.env.NODE_ENV === 'production' ? Configuration.value('backendHost') : 'http://localhost:8000/lawmens/'
    const sAuth1 = String('admin:') + String(rpc.getUserId()) + String(':') + String(rpc.getToken())
    const sAuth = String(btoa(sAuth1))
    const sessionAuth = String('Session ') + sAuth
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: sessionAuth,
      },
    }

    const payload = {
      id: '1',
      // method: 'model.site.operative.assignment.reject',
      method: 'model.project.site.assignment.undo_sign_in',
      params: [[assignmentId], { web_user_session: rpc.getWebToken() }],
    }  

    axios.post(path, payload, config)
    .then(response => {

      if (response.data.error) {
        generalMethods.messageBox('', 'Cancel sign in failed', 'error');
        return
      }

      if (response.data.result == null) {
        generalMethods.messageBox('', 'Sign in cancelled', 'success');
        // store.commit('app/getCurrentAssignment');
        return
      }

      if (response.data.result.length > 0) {
        console.log(response.data.result)
        return
      }

    })
    .catch(error => {
      console.log('Cancel catch:', error)
    })
  },   

  async getTodaysJobs(dateFrom, callback) { 
    console.log('getTodaysJobs')
    this.callback = callback
    store.commit('app/setIsLoadingAssigments', false);
    store.commit('app/setCheckedInStatus', '');
    const path = process.env.NODE_ENV === 'production' ? Configuration.value('backendHost') : 'http://localhost:8000/lawmens/'
    const sAuth1 = String('admin:') + String(rpc.getUserId()) + String(':') + String(rpc.getToken())
    const sAuth = String(btoa(sAuth1))
    const sessionAuth = String('Session ') + sAuth
    const config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: sessionAuth,
      },
    }

/*
    const date1 = new Date(dateFrom)
    console.log(date1)
    date1.setDate(date1.getDate() - 1);

    const dayFrom = date1.toLocaleString('default', { day: 'numeric' })
    const monthFrom = date1.toLocaleString('default', { month: 'numeric' })
    const yearFrom = date1.toLocaleString('default', { year: 'numeric' })

    date1.setDate(date1.getDate() + 2);

    const dayFrom2 = date1.toLocaleString('default', { day: 'numeric' })
    const monthFrom2 = date1.toLocaleString('default', { month: 'numeric' })
    const yearFrom2 = date1.toLocaleString('default', { year: 'numeric' })

    const filter = [['start_time', '>=', {"__class__": "datetime", "year": parseInt(yearFrom), "month": parseInt(monthFrom), "day": parseInt(dayFrom), "hour": 0, "minute": 0, "second": 0, "microsecond": 0}],['end_time', '<=', {"__class__": "datetime", "year": parseInt(yearFrom2), "month": parseInt(monthFrom2), "day": parseInt(dayFrom2), "hour": 23, "minute": 59, "second": 59, "microsecond": 9999}]]
    const order = [['start_time', 'ASC']]
*/

    const date1 = new Date(dateFrom)

    const dayFrom = date1.toLocaleString('default', { day: 'numeric' })
    const monthFrom = date1.toLocaleString('default', { month: 'numeric' })
    const yearFrom = date1.toLocaleString('default', { year: 'numeric' })

    const filter = [['start_time', '>=', {"__class__": "datetime", "year": parseInt(yearFrom), "month": parseInt(monthFrom), "day": parseInt(dayFrom), "hour": 0, "minute": 0, "second": 0, "microsecond": 0}],['start_time', '<=', {"__class__": "datetime", "year": parseInt(yearFrom), "month": parseInt(monthFrom), "day": parseInt(dayFrom), "hour": 23, "minute": 59, "second": 59, "microsecond": 9999}]]
    const order = [['start_time', 'ASC']]

    const payload = {
      id: '1',
      // method: 'model.site.operative.assignment.search_read',
      method: 'model.project.site.assignment.search_read',
      params: [filter, null, null, order, ['operative.id', 'operative.rec_name', 'operative_name', 'signed_in', 'signed_out', 'status', 'start_time'], { web_user_session: rpc.getWebToken() }], //'state', , 'number'
    }   


    try{
      const response = await axios.post(path, payload, config);
      if (response){
        var assign = []
        if (response.data.error !== undefined) {
          store.commit('app/setUserAssignmentsID', assign);                       
          if (this.callback) this.callback();
          return
        }

        if (response.data.result.length > 0) {
          var index = 0
          for (var i=0; i < response.data.result.length; ++i){
            if (response.data.result[i].status === 'accepted' && (response.data.result[i].signed_in === 0 || response.data.result[i].signed_in === null) && (response.data.result[i].signed_out === 0 || response.data.result[i].signed_out === null)){              
              assign[index] = response.data.result[i].id           
              index = index + 1
            }
            if ((response.data.result[i].status === 'on_site' || response.data.result[i].status === 'accepted') && response.data.result[i].signed_in === 1 && response.data.result[i].signed_out === 0){
              assign = []
              assign[0] = response.data.result[i].id
              break;    
            }
          }
          store.commit('app/setUserAssignmentsID', assign);                       
          if (this.callback) this.callback();
          return
        }else{
          store.commit('app/setUserAssignmentsID', assign);                       
          if (this.callback) this.callback();
        }
      }
    }catch(Exception){
      console.log('Error: getTodaysJobs - '+ Exception)
    } 
    /*
    axios.post(path, payload, config)
      .then(response => {
        var assign = []
        if (response.data.error !== undefined) {
          store.commit('app/setUserAssignmentsID', assign);                       
          if (this.callback) this.callback();
          return
        }

        if (response.data.result.length > 0) {
          var index = 0
          for (var i=0; i < response.data.result.length; ++i){
            if (response.data.result[i].status === 'accepted' && (response.data.result[i].signed_in === 0 || response.data.result[i].signed_in === null) && (response.data.result[i].signed_out === 0 || response.data.result[i].signed_out === null)){              
              assign[index] = response.data.result[i].id           
              index = index + 1
            }
            if (response.data.result[i].status === 'accepted' && response.data.result[i].signed_in === 1 && response.data.result[i].signed_out === 0){
              assign = []
              assign[0] = response.data.result[i].id
              break;    
            }
          }
          store.commit('app/setUserAssignmentsID', assign);                       
          if (this.callback) this.callback();
          return
        }else{
          store.commit('app/setUserAssignmentsID', assign);                       
          if (this.callback) this.callback();
        }
      })
      .catch(error => {
        console.log('getTodaysJobs catch:', error)
      })
      */
  },  

  async getTodaysJobsAssignId() { 
    console.log('getTodaysJobsAssignId')
    const dateFrom = new Date()

    const path = process.env.NODE_ENV === 'production' ? Configuration.value('backendHost') : 'http://localhost:8000/lawmens/'
    const sAuth1 = String('admin:') + String(rpc.getUserId()) + String(':') + String(rpc.getToken())
    const sAuth = String(btoa(sAuth1))
    const sessionAuth = String('Session ') + sAuth
    const config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: sessionAuth,
      },
    }
    const date1 = new Date(dateFrom)

    const dayFrom = date1.toLocaleString('default', { day: 'numeric' })
    const monthFrom = date1.toLocaleString('default', { month: 'numeric' })
    const yearFrom = date1.toLocaleString('default', { year: 'numeric' })

    const filter = [['start_time', '>=', {"__class__": "datetime", "year": parseInt(yearFrom), "month": parseInt(monthFrom), "day": parseInt(dayFrom), "hour": 0, "minute": 0, "second": 0, "microsecond": 0}],['start_time', '<=', {"__class__": "datetime", "year": parseInt(yearFrom), "month": parseInt(monthFrom), "day": parseInt(dayFrom), "hour": 23, "minute": 59, "second": 59, "microsecond": 9999}]]
    const order = [['start_time', 'ASC']]

    const payload = {
      id: '1',
      method: 'model.project.site.assignment.search_read',
      params: [filter, null, null, order, ['operative.id', 'operative.rec_name', 'operative_name', 'signed_in', 'signed_out', 'status', 'start_time'], { web_user_session: rpc.getWebToken() }], //'state', , 'number'
    }   

    try{
      const response = await axios.post(this.path, payload, config);
      if (response){
        var assign = []
        if (response.data.error !== undefined) {
          store.commit('app/setUserAssignmentsID', assign);                       
          if (this.callback) this.callback();
          return
        }

        if (response.data.result.length > 0) {
          var index = 0
          for (var i=0; i < response.data.result.length; ++i){
            if (response.data.result[i].status === 'accepted' && (response.data.result[i].signed_in === 0 || response.data.result[i].signed_in === null) && (response.data.result[i].signed_out === 0 || response.data.result[i].signed_out === null)){              
              assign[index] = response.data.result[i].id           
              index = index + 1
            }
            if ((response.data.result[i].status === 'on_site' || response.data.result[i].status === 'accepted') && response.data.result[i].signed_in === 1 && response.data.result[i].signed_out === 0){
              assign = []
              assign[0] = response.data.result[i].id
              break;    
            }
          }
          store.commit('app/setUserAssignmentsID', assign);                       
          if (this.callback) this.callback();
          return
        }else{
          store.commit('app/setUserAssignmentsID', assign);                       
          if (this.callback) this.callback();
        }
      }
    }catch(Exception){
      console.log('Error: CheckInExecute - '+ Exception)
    }     
    /*
      .then(response => {
        var assign = []
        if (response.data.error !== undefined) {
          store.commit('app/setUserAssignmentsID', assign);                       
          if (this.callback) this.callback();
          return
        }

        if (response.data.result.length > 0) {
          var index = 0
          for (var i=0; i < response.data.result.length; ++i){
            if (response.data.result[i].status === 'accepted' && (response.data.result[i].signed_in === 0 || response.data.result[i].signed_in === null) && (response.data.result[i].signed_out === 0 || response.data.result[i].signed_out === null)){              
              assign[index] = response.data.result[i].id           
              index = index + 1
            }
            if (response.data.result[i].status === 'accepted' && response.data.result[i].signed_in === 1 && response.data.result[i].signed_out === 0){
              assign = []
              assign[0] = response.data.result[i].id
              break;    
            }
          }
          store.commit('app/setUserAssignmentsID', assign);                       
          if (this.callback) this.callback();
          return
        }else{
          store.commit('app/setUserAssignmentsID', assign);                       
          if (this.callback) this.callback();
        }
      })
      .catch(error => {
        console.log('getTodaysJobsAssignId catch:', error)
      })
      */
  }, 

  getJobsList(vuexContext, dateFrom, callback) { // , ordertype
    console.log('getJobsList co')
    this.callback = callback
    const path = process.env.NODE_ENV === 'production' ? Configuration.value('backendHost') : 'http://localhost:8000/lawmens/'
    const sAuth1 = String('admin:') + String(rpc.getUserId()) + String(':') + String(rpc.getToken())
    const sAuth = String(btoa(sAuth1))
    const sessionAuth = String('Session ') + sAuth
    const config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: sessionAuth,
      },
    }
    // console.log(ordertype)
    const date1 = new Date(dateFrom)
    // console.log(date1)

    const dayFrom = date1.toLocaleString('default', { day: 'numeric' })
    const monthFrom = date1.toLocaleString('default', { month: 'numeric' })
    const yearFrom = date1.toLocaleString('default', { year: 'numeric' })
   
    // const filter = ['start_time', '>=', {"__class__": "datetime", "year": parseInt(yearFrom), "month": parseInt(monthFrom), "day": parseInt(dayFrom), "hour": 0, "minute": 0, "second": 0, "microsecond": 0}]

    const filter = ['OR', [['start_time', '>=', {"__class__": "datetime", "year": parseInt(yearFrom), "month": parseInt(monthFrom), "day": parseInt(dayFrom), "hour": 0, "minute": 0, "second": 0, "microsecond": 0}], ['end_time', '<', NOW], ['state', 'in', ['attended', 'on_site']]], [['end_time', '>', NOW]]]

    const offset = 0
    const limit = 60
    const order = [['start_time', 'ASC']] // 'DESC' // ,['time', 'ASC']

    const payload = {
      id: '1',
      // method: 'model.site.operative.assignment.search_read',
      method: 'model.project.site.assignment.search_read',
      params: [[filter], offset, limit, order, ['operative.id', 'operative.rec_name', 'operative_name', 'site_name', 'site_main_address', 'site_postal_code', 'site_latitude', 'site_longitude', 'contact_name', 'contact_value', 'start_time', 'end_time', 'role_name', 'signed_in', 'signed_out', 'status',  'site_opening_time', 'site_closing_time', 'site_number', 'create_date', 'write_date', 'customer_name', 'department_name'], { web_user_session: rpc.getWebToken() }], //'number',
    }
    

    axios.post(path, payload, config)
      .then(response => {
        // console.log('getJobsList resp')
        // console.log(response)
        vuexContext.commit('clearProjectsList', [])
        if (response.data.error !== undefined) {
          return
        }

        if (response.data.result.length > 0) {
          console.log(response.data.result)
          vuexContext.commit('setProjectsList', response.data.result)
         // vuexContext.commit('setNotificationsList', response.data.result)
          if (this.callback) this.callback();
          return
        }

      })
      .catch(error => {
        console.log('getJobsList catch:', error)
      })
  },   
}
/* eslint-enable */
