<template>
  <div class="oci-check-in-out-body"> 
      <b-table
        v-for="project in projectsList"
        :fields="fields"
        :items="project.job"
        @row-clicked="projectSelected(project.id, project.job.state, project.date)"
        responsive
        small
        borderless
        id="projectstable"
        style="box-shadow: 0px 0px 0px 1px rgba(0,0,0,0.05); border-radius: 8px; margin-top: 6px; margin-bottom: 6px; text-align: left;"
      >            
      <template #cell(site_main_address)="data">                
        <div 
          :style="'display: flex; justify-content: space-between; align-items: center; ' + checkIfToday(project.date, project.job.state)" 
          :id="getId(project.id, project.date)"
        >
          <span>
            &nbsp;{{ formatDate(project.date) }}&nbsp;&nbsp;
            <label 
              v-if="isToday(project.date)" 
              :style="getIsTodayColor()"
            >
              &nbsp;&nbsp;TODAY!&nbsp;&nbsp;
            </label>
          </span>
        </div>
        <div style="align: left" class="spaced">
          &nbsp;{{ project.job.department_name }}
        </div>
        <div style="align: left; display: flex; align-items: center;" class="spaced">
          &nbsp;
          <feather-icon 
            id="type" 
            icon="ClockIcon" 
            size="14" 
            style="stroke-width: 3; position: relative; top: -1px; bottom: -1px; margin-right: 4px;" 
          />
          {{ formatTime(project.date) }}
        </div>
        <div v-if="project.job.site_main_address" :style="SetAddressStyle(project.date, project.job.state)">
          &nbsp;{{ project.job.site_main_address.split('\n')[0] }}
        </div>
        <div v-else :style="SetAddressStyle(project.date, project.job.state)">
          -
        </div>
      </template>
      <template #cell(site_postal_code)="data">                
        <div 
          :style="'display: flex; justify-content: space-between; align-items: center; ' + checkIfToday(project.date, project.job.state)" 
          :id="getId(project.id, project.date)"
        >
          <span style="flex-grow: 1;">&nbsp;</span>
          <label 
            :style="getStatusColor(project.job.state) + ' padding: 1px 1px; border-radius: 25px;'"
          >
            {{ getJobStatus(project.job.state) }}
          </label>
        </div>
        <div class="spaced">
          &nbsp;
        </div>
        <div class="spaced">
          &nbsp;
        </div>
        <div 
          :style="'display: flex; justify-content: flex-end; align-items: center; ' + SetPostcodeStyle(project.date, project.job.state)"
        >
          <span>
            {{ project.job.site_postal_code ? project.job.site_postal_code : '-' }}
          </span>
        </div>                
      </template>       
    </b-table>

    <div v-for="(booking, index) in bookingsList" :key="index" >
    <b-table
        :fields="fields2"
        :items="[booking]"
        @row-clicked="bookingSelected(booking.id, booking.status)"
        responsive
        small
        borderless
        :id="'bookingstable-' + index"
        style="box-shadow: 0px 0px 0px 1px rgba(0,0,0,0.05); border-radius: 8px; margin-top: 6px; margin-bottom: 6px; text-align: left;"
    >            
        <template #cell(site_main_address)="data">                
            <div :style="checkIfToday(data.item.booked_for, data.item.status)" class="spaced">
              &nbsp;{{ formatDate(data.item.booked_for) }}&nbsp;&nbsp;
              <label v-if="isToday(data.item.booked_for)" :style="getIsTodayColor()">&nbsp;&nbsp;TODAY!&nbsp;&nbsp;</label>
            </div>
            <div style="align: left" class="spaced">
              &nbsp;<feather-icon v-if="data.item.type == 'Waste'" id="type" icon="TrashIcon" size="14" style="stroke-width: 3; position: relative; top: -2px;"/>
              <feather-icon v-if="data.item.type == 'Delivery / Collection'" id="type" :icon="getTruckIcon()" size="14" style="stroke-width: 3; position: relative; top: -1px;"/>&nbsp;
                {{ data.item.type }}
            </div>
            <div style="align: left" class="spaced">
              &nbsp;<feather-icon id="type" icon="ClockIcon" size="14" style="stroke-width: 3; position: relative; top: -2px;"/>
                {{ formatTime(data.item.booked_for) }}
            </div>
            <div :style="SetAddressStyle(data.item.booked_for, data.item.status)">
                {{ data.item.site_main_address.split('\n')[0] }}
            </div>
        </template>
        <template #cell(site_postal_code)="data">                
            <div class="text-right spaced">                    
                <label :style="getStatusColor(data.item.status)">{{getBookingStatus(data.item.status)}}</label>
            </div>
            <div class="spaced">
              &nbsp;
            </div>
            <div class="spaced">
              &nbsp;
            </div>
            <div :style="SetPostcodeStyle(data.item.booked_for, data.item.status)" class="text-right">
                {{ data.item.site_postal_code }}
            </div>                
        </template>           
    </b-table>
    </div>
  </div>
</template>
<script>
import {
  BTable, BRow, BCol, BButton, BCard,
} from 'bootstrap-vue'
import { projectsMethods } from '@/components/ui/ProjectsFunctions'
import { checkInOutMethods } from '@/components/ui/CheckInOutFunctions'
import { mapGetters } from 'vuex'
import store from '@/store'
import router from '@/router'

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BButton,
    BCard,
  },
  computed: {
    ...mapGetters({ assignmentID: 'app/getUserAssignmentsID' }),
    ...mapGetters({ projectsList: 'app/getProjectsList' }),
    ...mapGetters({ bookingsList: 'app/getDriversBookingsList' }),
  },    
  data() {
    return {
      icon: "RefreshCwIcon",
      color: "#6E6B7B",
      scrollPosition: 0,
      message: '',
      showMessage: false,
      showLogin: false,
      showtoday: true,
      buttonmsg: "Today",
      fields: [           
        {
          key: 'site_main_address', label: '', thClass: 'bg-white', tdClass: 'bg-white', 
        },
        {
          key: 'site_postal_code', label: '', thClass: 'bg-white', tdClass: 'bg-white', 
        },          
      ],
      fields2: [          
        {
          key: 'site_main_address', label: '', thClass: 'bg-white', tdClass: 'bg-white', 
        },
        {
          key: 'site_postal_code', label: '', thClass: 'bg-white', tdClass: 'bg-white', 
        },          
      ],      
    }
  },
  created() {    
    this.LoadData()  
    // this.LoadData2()  
  },  
  methods: {
    LoadData() {      
      console.log('project loaddata')
      this.color = "#49A7EA"

      this.$store.dispatch('app/getTodaysAssignmentsAndBookings')            
      
      setTimeout(() => this.color = "#6E6B7B", 250);

      setTimeout(() => this.SetScrollOnTodayJob(), 700);
    },
    LoadData2() {
      let dBefore = 0
      let dAfter = 0
      this.color = "#49A7EA"
      const dateFrom = new Date()
      dateFrom.setDate(dateFrom.getDate()-dBefore)
      const dateTo = new Date()
      dateTo.setDate(dateTo.getDate()+dAfter)
      this.$store.dispatch('app/getBookingsList', {dateFrom, dateTo})           
      setTimeout(() => this.color = "#6E6B7B", 250);
    },      
    SetScrollOnTodayJob() {
      var element = document.getElementById("special");
      if(element) { 
        element.scrollIntoView();  
      }
    },     
    formatDate(data) {
      const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ];      
      return String(data.day) +' '+ monthNames[data.month-1]+' '+ String(data.year)      
    },
    GoToBack() {
      // console.log('GoToBack')
      // console.log(this.assignmentID)
      // store.commit('app/changeUserAssignmentsID', this.assignmentID)
      this.$router.go(-1)
    },
    getBookingStatus(state) {    
      // console.log(state)  
      if(state === 'to_do')
        return '\u00A0\u00A0TO DO\u00A0\u00A0'
      else if(state === 'started')
        return '\u00A0\u00A0STARTED\u00A0\u00A0'
      else if(state === 'completed')
        return '\u00A0\u00A0COMPLETED\u00A0\u00A0'
      else if(state === 'rejected')
        return '\u00A0\u00A0Rejected\u00A0\u00A0'
      else if(state === 'cancelled')
        return '\u00A0\u00A0CANCELLED\u00A0\u00A0'
      else
        return '\u00A0\u00A0COMPLETED\u00A0\u00A0'
    },
    getStateCSS(state) {
      console.log(state)
      if(state === 'accepted')
        return 'text-left bg-success'
      if(state === 'unable_to_attend')
        return 'text-left bg-danger'        
      if(state === 'on_site')
        return 'text-left bg-success'        
      if(state === "offered")
        return 'text-left bg-primary'   
      if(state === 'rejected')
        return 'text-left bg-danger'    
      if(state === 'unaccepted')
        return 'text-left bg-danger'               
    },   
    getJobStatus(state) {     
      if(state === 'offered')
        return '\u00A0\u00A0OFFERED\u00A0\u00A0'  
      else if(state === 'unaccepted')
        return '\u00A0\u00A0REJECTED\u00A0\u00A0'
      else if(state === 'on_site')
        return '\u00A0\u00A0ACCEPTED\u00A0\u00A0'    
      else if(state === 'unable_to_attend')
        return '\u00A0\u00A0UNABLETOATTEND\u00A0\u00A0'
      else if(state === 'no_show')
        return '\u00A0\u00A0NOSHOW\u00A0\u00A0'
      else if(state === 'completed')
        return '\u00A0\u00A0COMPLETED\u00A0\u00A0'   
      else if(state === 'accepted')
        return '\u00A0\u00A0ACCEPTED\u00A0\u00A0'                 
      else
        return state 
    },   
    tdClassFunc(row) {
      //if (row > 50) {
      //  return 'bg-primary';
      //}
      //return 'bg-primary';
    },
    handleScroll({ target: { scrollTop, clientHeight, scrollHeight }}){  
      // console.log(scrollTop)       
      // console.log(clientHeight)       
      // console.log(scrollHeight)       
      if (scrollTop + clientHeight >= scrollHeight) {
        // check the date of the last in the list and ask for next 5 days        
        console.log('bottom')
        if (this.projectsList.length > 0){
          // console.log(this.projectsList[this.projectsList.length-1].date.year)
          // console.log(this.projectsList[this.projectsList.length-1].date.month)
          // console.log(this.projectsList[this.projectsList.length-1].date.day)
          const mainDate = new Date(this.projectsList[this.projectsList.length-1].date.year, this.projectsList[this.projectsList.length-1].date.month-1, this.projectsList[this.projectsList.length-1].date.day)
          mainDate.setDate(mainDate.getDate() - 4)
          
          this.$store.dispatch('app/getJobsList', mainDate) //, DESC
        }         
      } else if (scrollTop == 0) {        // scrollTop + clientHeight < scrollHeight
        // check the date of the first in the list and ask for previous 5 days
        console.log('top')     
        if (this.projectsList.length > 0){
          // console.log(this.projectsList[0].date.year)
          // console.log(this.projectsList[0].date.month)
          // console.log(this.projectsList[0].date.day)
          const mainDate = new Date(this.projectsList[0].date.year, this.projectsList[0].date.month-1, this.projectsList[0].date.day)
          mainDate.setDate(mainDate.getDate() - 4)  

          this.$store.dispatch('app/getJobsList', mainDate) //, DESC
        }  
      } else {
        console.log('scrolling')
      }
    },
    scrollUp(){
      if (this.projectsList.length > 0){
        // console.log(this.projectsList[0].date.year)
        // console.log(this.projectsList[0].date.month)
        // console.log(this.projectsList[0].date.day)
        const mainDate = new Date(this.projectsList[0].date.year, this.projectsList[0].date.month-1, this.projectsList[0].date.day)
        mainDate.setDate(mainDate.getDate() - 4)  

        this.$store.dispatch('app/getJobsList', mainDate) //, DESC
      }else{
        const mainDate = new Date()
        mainDate.setDate(mainDate.getDate())
        this.$store.dispatch('app/getJobsList', mainDate)        
      }             
    },
    scrollDown(){
      if (this.projectsList.length > 0){
        // console.log(this.projectsList[this.projectsList.length-1].date.year)
        // console.log(this.projectsList[this.projectsList.length-1].date.month)
        // console.log(this.projectsList[this.projectsList.length-1].date.day)
        const mainDate = new Date(this.projectsList[this.projectsList.length-1].date.year, this.projectsList[this.projectsList.length-1].date.month-1, this.projectsList[this.projectsList.length-1].date.day)
        mainDate.setDate(mainDate.getDate() - 4)
        
        this.$store.dispatch('app/getJobsList', mainDate) //, DESC
      }else{
        const mainDate = new Date()
        mainDate.setDate(mainDate.getDate())
        this.$store.dispatch('app/getJobsList', mainDate)        
      } 
    },    
    getStatusColor(state){
      // return 'color: red' 
      if(state === 'accepted') 
        return 'color: #49A7EA; background-color: rgba(73, 167, 234, 0.2); border-radius: 25px; font-weight: bold;'
      if(state === 'unable_to_attend') 
        return 'color: #EA5455; background-color: rgba(234, 84, 85, 0.2); border-radius: 25px; font-weight: bold;'       
      if(state === 'no_show') 
        return 'color: #EA5455; background-color: rgba(234, 84, 85, 0.2); border-radius: 25px; font-weight: bold;'         
      if(state === 'on_site') 
        return 'color: #49A7EA; background-color: rgba(73, 167, 234, 0.2); border-radius: 25px; font-weight: bold;'        
      if(state === 'offered') 
        return 'color: #FF9F43; background-color: rgba(255, 159, 67, 0.2); border-radius: 25px; font-weight: bold;'   
      if(state === 'rejected') 
        return 'color: #EA5455; background-color: rgba(234, 84, 85, 0.2); border-radius: 25px; font-weight: bold;'
      if(state === 'completed') // attended
        return 'color: #6E6B7B; background-color: rgba(110, 107, 123, 0.2); border-radius: 25px; font-weight: bold;'        
      if(state === 'unaccepted')
        return 'color: red; background-color: #f3d9d9; border-radius: 25px; font-weight: bold;'        

      if(state === 'to_do') 
        return 'color: #FF9F43; background-color: rgba(255, 159, 67, 0.2); border-radius: 25px; font-weight: bold;'   
      if(state === 'started') 
        return 'color: #49A7EA; background-color: rgba(73, 167, 234, 0.2); border-radius: 25px; font-weight: bold;'     
      if(state === 'cancelled')
        return 'color: #EA5455; background-color: rgba(234, 84, 85, 0.2); border-radius: 25px; font-weight: bold;' 
      // on_site -> sign in
      // attended -> sign in and sign out
      // no_response -> they did not even accepted

    },
    getIsTodayColor(){     
      return 'color: #002437; background-color: #F6FF00; border-radius: 25px; font-weight: bold;'            
    },
    getTruckIcon() {
      return 'TruckIcon'
    },
    isToday(data) {
      if (!data) {
        return false;
      }
      const today = new Date()
      if (today.getDate() === data.day && today.getMonth()+1 === data.month && today.getFullYear() === data.year)
        return true;
      else
        return false;
    },
    bookingSelected(bookingId, status) {
        console.log('bookingSelected', bookingId, status)  
        store.commit('app/changeDriverBookingID', bookingId)
        this.$router.push('/driverprocess');
    },     
    SetAddressStyle(data, state){
      if(state === 'completed') {
        return 'align-text: left; color: #cecdd6'
      } else {
        const today = new Date()    
        if (today.getDate() === data.day && today.getMonth()+1 === data.month && today.getFullYear() === data.year)      
          return 'align-text: left; font-weight: bold;'
        else
          return 'align-text: left;'  
      }    
    },
    SetPostcodeStyle(data, state){
      if(state === 'completed'){
        return 'align-text: right; color: #cecdd6'
      } else {
        const today = new Date()  
        if (today.getDate() === data.day && today.getMonth()+1 === data.month && today.getFullYear() === data.year)      
          return 'align-text: right; font-weight: bold;'
        else
          return 'align-text: right;'
      }
    },    
    checkIfToday(data, state){
      const today = new Date()    
      if (today.getDate() === data.day && today.getMonth()+1 === data.month && today.getFullYear() === data.year)
        if(state === 'completed')
          return 'align: left; color: rgb(145, 185, 145); font-weight: bold;'
        else     
          return 'align: left; color: green; font-weight: bold;' // border-bottom-style: solid; border-bottom-color: green;
      else
        if(state === 'completed')
          return 'align: left: color: #cecdd6'
        else
          return 'align: left'
    },
    formatTime(data) {
      if (!data || data.hour == null || data.minute == null) {
        return "-";
      }

      let hour = data.hour;
      const minute = data.minute.toString().padStart(2, "0");
      const period = hour >= 12 ? "pm" : "am";

      // Convert hour to 12-hour format
      hour = hour % 12 || 12;

      return `${hour}:${minute} ${period}`;
    },    
    projectSelected(assignmentId, state, date) {    
      store.commit('app/setFromAllTasks', true)
      console.log('state', state)
      console.log('assignmentId', assignmentId)
      console.log('date', date)
      const today = new Date()

      store.commit('app/changeUserAssignmentsID', assignmentId)  
      store.commit('app/setAllowAcceptReject', true)

      if (state === 'accepted') {   
        store.commit('app/setGoToTask', true)
        if (today.getFullYear() === date.year && today.getMonth()+1 === date.month && today.getDate() === date.day) {
          // console.log('checkin')
          this.$store.dispatch('app/getCurrentJobForCurrentDay', this.assignmentID)
          store.commit('app/setIsFromProjectListPage', true)
          this.$router.push('/'); 
        }
      }else {
        store.commit('app/setGoToTask', false)
      }          
    },
    getId(id, data){
      const today = new Date()
      if (today.getDate() === data.day && today.getMonth()+1 === data.month && today.getFullYear() === data.year){
        return 'special' //to do the automatic scroll
      }else{
        return id
      }
    },
  },
}
</script>

<style scoped>
.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  width: 100%;
}

.sub-header-item-1 {
  grid-column: 1;
  backdrop-filter: #fff;
  width: 50%;
}

.sub-header-item-2 {
  grid-column: 2;
  background: #fff;
  width: 50%;
}

.wrapper {
  overflow: auto;
  height: 70vh;
  scrollbar-width: none;
}
 </style>
